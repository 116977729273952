import React from 'react'
import cascading from '../assests/css/all.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { getTokenPricesFromFarm } from '../hooks/useFarms';
import { getNativeAddress } from '../hooks/useNativeToken';
import { useState } from 'react';
import { dateTimeFormat } from '../lib/DateFormat'
import { useNavigate } from 'react-router-dom';
import { MdOutlineArrowForward } from "react-icons/md";


export default function Launchpaddashcard(props) {
    console.log(props, "Launchpaddashcard");
    const navigate = useNavigate()


    const {datas,allocBal} =  props
    const [totalShareInUsd,settotalShareInUsd] = useState('')
    const [XinUsd,setXinUsd] = useState('')



    useEffect(()=>{
        setUSDPrice()
    },[datas,allocBal])

    const setUSDPrice = async()=>{
        try{
            let farms  =  localStorage.getItem('FARMS')
            let price =  await getTokenPricesFromFarm(farms)
            console.log("farms________price",price)
            let nativeAddress  =  getNativeAddress()
            console.log("nativeAddress",nativeAddress)
            let XinUsd  =  price[nativeAddress.toLowerCase()]
            console.log("XinUsd",XinUsd)
            setXinUsd(parseFloat(XinUsd))
            let totalShareInUsd  =  parseFloat(XinUsd)*parseFloat(props.allocBal)
            console.log("totalShareInUsd",totalShareInUsd,props.allocBal)
            settotalShareInUsd(parseFloat(totalShareInUsd))
        }catch(err){
            console.log(err,'setUSDPrice__err')
        }
    }
    return (
        <div className={`${cascading.dashcard} mb-4`} onClick={props.navi}>
            <div className={`${cascading.topsec} mb-3`}>
                <div className={`${cascading.imgsec}`}>
                    <img src={props?.datas?.logo} alt='img' className={`img-fluid ${cascading.dashimg}`} />
                </div>
                <div>
                    <p className={`${cascading.datelaunchpad}`}>{dateTimeFormat(props?.datas?.endTime*1000)}</p>

                </div>

            </div>
            <div>
                <p className={`${cascading.name}`}>{props?.datas?.name}</p>
                <p className={`${cascading.desc}`}>{props?.datas?.description}</p>
                <div className={`${cascading.innercard}`}>
                    <div>
                        <p className={`${cascading.labelname}`}>Your WL allocation</p>
                        <p className={`${cascading.value}`}>${isNaN(totalShareInUsd) ? 0 : totalShareInUsd}</p>
                    </div>
                    <div>
                        <p className={`${cascading.labelname}`}>Guaranteed WL allocation ratio</p>
                        <p className={`${cascading.value}`}>${XinUsd}/YUM</p>
                    </div>
                    {/* <div>
                <p  className={`${cascading.labelname}`}>Snapshot date</p>
                <p className={`${cascading.value}`}>{props.datas.fees}</p>
            </div> */}
                </div>
            </div>
             
             <button className={`${cascading.connect_btn_theme}`} onClick={()=>{navigate(`/launchpaddetail/${props?.datas?.saleAddress}`)}}>Launch Detail <MdOutlineArrowForward className='ms-2'/></button>
        </div>
    )
}
