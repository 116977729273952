import React, { useState } from 'react'
import cascading from '../assests/css/all.module.css';
import logo from '../assests/images/cardlogo.png'
import Walletmodal from '../modals/walletmodal';
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";

import { useSelector } from 'react-redux';
import pools_icon from '../assests/images/pools_icon.png';

import ReactDatatable from '@ashvin27/react-datatable';


import { AiOutlineCalculator } from "react-icons/ai";
import { Link } from 'react-router-dom';
import Calculatormodal from '../modals/calculatormodal';
import { Accordion, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';
import { useNavigate } from 'react-router-dom';

export default function Yumpooldetailtable(props) {
    const navigate = useNavigate()


    const config = {
        button: {
            excel: false,
            print: false,
            extra: false,
        },
        // show_filter:false,
        show_length_menu: false,
        show_pagination: false
    }

    const columns = [
        {
            key: "pool",
            text: "Pool",
            className: "pool",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>
                        <div className={`${cascading.imgsec}`}>
                            <div className={`${cascading.leftimgsec}`}>
                                <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                            </div>
                            <p className={`${cascading.coinname} mb-0`}>DSP-BNB</p>


                        </div>

                    </>
                );
            }

        },
        {
            key: "tvl",
            text: "TVL",
            className: "tvl",
            align: "left",
            sortable: true,

        },
        {
            key: "aprrange",
            text: "APR Range",
            className: "aprrange",
            sortable: true,
            width: 80,
            cell: records => {
                return (
                    <div className='d-flex align-items-center'>
                        <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>10.39% <FaAngleRight /><span className={`${cascading.themeapr}`}>12.34%</span></p>
                        <div>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-1'>Swap fees APR: 8.02%</p>
                                        <p className='mb-1'>Farm APR: 0.02% - 2.89%</p>
                                        <p className='mb-0'>YUM rewards: +3.14%</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    // <img src={tick} alt='tick' className={`img-fluid`} width={17} height={17}/>
                )

            }
        },
        {
            key: "deposits",
            text: "Your Deposits",
            className: "deposits",
            align: "left",
            sortable: true
        },
        {
            key: "rewards",
            text: "Pending Rewards",
            className: "rewards",
            sortable: true,
            align: "left"
        },
        {
            key: "pool",
            text: "",
            className: "pool",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>
                        <div className={`${cascading.imgsec}`}>

                            <Link to="/yumremoveliquidity"
                                // onClick={()=>{alert(1);navigate("/yumpooldetail")}}
                                className={`${cascading.btnicondelete}`}>Remove</Link>


                        </div>

                    </>
                );
            }

        },


    ];

    const records = [
        {
            "pool": "",
            "tvl": "$4.3M",
            "aprrange": "",
            "deposits": "0",
            "rewards": "0",
        },
        {
            "pool": "",
            "tvl": "$4.3M",
            "aprrange": "",
            "deposits": "0",
            "rewards": "0",
        },
        {
            "pool": "",
            "tvl": "$4.3M",
            "aprrange": "",
            "deposits": "0",
            "rewards": "0",
        },
        {
            "pool": "",
            "tvl": "$4.3M",
            "aprrange": "",
            "deposits": "0",
            "rewards": "0",
        },
        {
            "pool": "",
            "tvl": "$4.3M",
            "aprrange": "",
            "deposits": "0",
            "rewards": "0",
        }

    ];


    const userdetail = useSelector((state) => state.admin)
    return (
        <>
            <div className={`${cascading.innerpages} ${cascading.launchpadpage} ${cascading.pooltable} ${cascading.pooltablewidth}`}>


                <div className={`${cascading.tabstyle} mt-3 table-responsive`} id='launchpadtable' >

                    <ReactDatatable
                        // data={data}
                        className={`${cascading.datatablestyle}`}
                        config={config}
                        columns={columns}
                        records={records}
                    // onRowClicked={()=>navigate("/yumpooldetail")}
                    />
                </div>
            </div>
        </>

    )
}
