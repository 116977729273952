import React, { useEffect, useState } from 'react';

import { IoMdSettings } from "react-icons/io";
import { MdHistory } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';

import cascading from '../../assests/css/createposition.module.css';
import Footer from '../../common/footer'
import { FaArrowDown, FaPlus } from "react-icons/fa";
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import { FaSyncAlt } from "react-icons/fa";


import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Accordion from 'react-bootstrap/Accordion';

import { FaRegQuestionCircle, FaArrowRight, FaChevronLeft, FaCopy } from "react-icons/fa";
import Header from '../../common/header';

import CopyToClipboard from "react-copy-to-clipboard";


import { useLocation, useNavigate, Link} from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import { useSelector } from 'react-redux';


//images
import bnbimg from '../../assests/images/bnb.png'
import iconimg1 from '../../assests/images/coinicon.png'

//modals
import Walletmodal from '../../modals/walletmodal';
import Recenthistory from '../../modals/recenthistory';
import Selecttoken from '../../modals/selecttoken';
import Settingsmodal from '../../modals/settingsmodal';
import ConfirmSwapmodal from '../../modals/confirmSwapmodal';
import Addliquiditymodal from '../../modals/addliquiditymodal';
import Addsuccessmodal from '../../modals/addsuccessmodal';

//lib
import { isEmpty } from '../../lib/isEmpty';
import { getWalletAddress } from '../../lib/localStorage';
import { IsCurrency, roundToSignificant } from '../../hooks/useCommon';
import fileObjectUrl from '../../lib/img'
import { toFixedNumber } from '../../lib/FixedNumber';

//hooks
import { getTokens, getCurrencyBalance, multicall } from '../../hooks/useTokens'
import { Balance } from '../../hooks/useErc20'
import { GetPairaddress, GetPairInfo } from '../../hooks/useFactory';
import { CreatePool, GetPool } from '../../hooks/useNFTpoolFactory';
import { GetAmountsOut, GetAmountsIn } from '../../hooks/useRouter'
import { CheckIsApproved, ApproveToken } from '../../hooks/useTokens';

//env
import { ZEROTH_ADDRESS, MINIMUM_LIQUIDITY, MAX_UNIT256 } from '../../config/env';
import BigNumber from 'bignumber.js';

//ABI
import pair_ABI from '../../config/abi/pairAbi.json'
import { getPositionHelperAddress } from '../../hooks/usePositionHelper';




export default function Createposition() {
  const navigate = useNavigate()
  const [swapsec, setSwapsec] = useState(true)
  const [walletmodalshow, setWalletmodalshow] = useState(false)
  const [recentshow, setRecentshow] = useState(false);
  const [settingshow, setSettingshow] = useState(false)
  const [swapmodal, setSwapmodal] = useState(false)
  const [liquiditymodal, setLiquiditymodal] = useState(false)
  const [modalsucess, setmodalsuccess] = useState(false);

  const [tokenA, setTokenA] = useState({})
  const [tokenB, setTokenB] = useState({})
  const [tokenAval, setTokenAval] = useState('')
  const [tokenBval, setTokenBval] = useState('')
  const [commontoken, setCommontoken] = useState(false)
  const [tokenshow, setTokenshow] = useState(false);
  const [tokenshow2, setTokenshow2] = useState(false);
  const [poolAddress, setpoolAddress] = useState('')
  const [lpAddress, setlpAddress] = useState('')
  const [lpbalance, setlpBalance] = useState('')
  const [reserveA, setReserveA] = useState('')
  const [reserveB, setReserveB] = useState('')
  const [token0, setToken0] = useState('')
  const [token1, setToken1] = useState('')
  const [lpTotalsupp, setLpTotalsupp] = useState('')
  const [toTokenper, setToTokenper] = useState(0);
  const [fromTokenper, setFromTokenper] = useState(0);
  const [poolShare, setPoolShare] = useState(0);
  const [getLiquidity, setgetLiquidity] = useState(0)
  const [pairAddress, setPairAddress] = useState('')
  const [SwapeRate0, setSwapeRate0] = useState('')
  const [SwapeRate1, setSwapeRate1] = useState('')
  const [tokenAapprove, setTokenAapprove] = useState(false)
  const [tokenBapprove, setTokenBapprove] = useState(false)
  const [successLoader, setsuccessLoader] = useState(false)
  const [loaderText, setloaderText] = useState('')
  const [successText, setsuccessText] = useState('')
  const [errorText, seterrorText] = useState('')
  const [Trxn, setTrxn] = useState('')

  const userdetail = useSelector((state) => state.admin)


  const { state } = useLocation()

  useEffect(() => {
    if (!isEmpty(state)) {
      InitalDataSet()
    }
  }, [state])

  const SetInitalData = ()=>{
    try{
      setTokenA({})
      setTokenB({})
      setTokenAval('')
      setTokenBval('')
      setpoolAddress('')
      setlpAddress('')
      setlpBalance('')
      setReserveA('')
      setReserveB('')
      setToken0('')
      setToken1('')
      setLpTotalsupp('')
      setToTokenper('')
      setFromTokenper('')
      setPoolShare(0)
      setgetLiquidity(0)
      setPairAddress('')
      setSwapeRate0('')
      setSwapeRate1('')
      setTokenAapprove(false)
      setTokenBapprove(false)

    }catch(err){
      console.log('SetInitalData__Err',err)
    }
  }


  const InitalDataSet = async () => {
    try {
      setTokenA(state.token0)
      setTokenB(state.token1)
      isPoolCheck(state.token0, state.token1)
      if (IsCurrency(state.token0)) {
        setTokenAapprove(true)
      } else {
        let tokenAapprove = await CheckIsApproved(getPositionHelperAddress(), MAX_UNIT256, getWalletAddress(), state.token0.address)
        setTokenAapprove(tokenAapprove)
      }

      if (IsCurrency(state.token1)) {
        setTokenAapprove(true)
      } else {
        let tokenBapprove = await CheckIsApproved(getPositionHelperAddress(), MAX_UNIT256, getWalletAddress(), state.token1.address)
        setTokenBapprove(tokenBapprove)
      }
    } catch (err) {
      console.log(err, 'InitalDataSet__err')
    }
  }



  //onChange func
  const onFromTokenChange = async value => {
    try {
      if (value.address == tokenB?.address) {
        setTokenB(tokenA)
        if (IsCurrency(tokenA)) {
          setTokenBapprove(true)
        } else {
          let tokenBapprove = await CheckIsApproved(getPositionHelperAddress(), MAX_UNIT256, getWalletAddress(), tokenA.address)
          setTokenBapprove(tokenBapprove)
        }
        isPoolCheck(value, tokenA)
      } else {
        isPoolCheck(value, tokenB)
      }
      setTokenA(value);
      if (IsCurrency(value)) {
        setTokenAapprove(true)
      } else {
        let tokenAapprove = await CheckIsApproved(getPositionHelperAddress(), MAX_UNIT256, getWalletAddress(), value.address)
        setTokenAapprove(tokenAapprove)
      }


    } catch (err) {
      console.log(err, 'onFromTokenChange__err')
    }
  };

  const onToTokenChange = async value => {
    try {
      console.log(value, "onToTokenChange_value")
      if (value.address == tokenA?.address) {
        setTokenA(tokenB)
        if (IsCurrency(tokenB)) {
          setTokenAapprove(true)
        } else {
          let tokenAapprove = await CheckIsApproved(getPositionHelperAddress(), MAX_UNIT256, getWalletAddress(), tokenB.address)
          setTokenAapprove(tokenAapprove)
        }
        isPoolCheck(tokenB, value)
      } else {
        isPoolCheck(tokenA, value)
      }
      setTokenB(value);
      if (IsCurrency(value)) {
        setTokenBapprove(true)
      } else {
        let tokenBapprove = await CheckIsApproved(getPositionHelperAddress(), MAX_UNIT256, getWalletAddress(), value.address)
        setTokenBapprove(tokenBapprove)
      }

    } catch (err) {
      console.log(err, 'onToTokenChange__err')
    }
  };



  const priceCalculation = (fromValue, toValue) => {
    try {
      if (!isEmpty(fromValue) && !isEmpty(toValue)) {
        let totokenper = toValue / fromValue
        totokenper = !isNaN(totokenper) && isFinite(totokenper) ? totokenper : 0
        setToTokenper(totokenper)
        let fromtokenper = fromValue / toValue
        fromtokenper = !isNaN(fromtokenper) && isFinite(fromtokenper) ? fromtokenper : 0
        setFromTokenper(fromtokenper)
        console.log("priceCalculation", totokenper, fromtokenper, isFinite(fromtokenper))
        if (pairAddress !== ZEROTH_ADDRESS) {
          let liquidity = Math.min(fromValue * lpTotalsupp / reserveA, toValue * lpTotalsupp / reserveB);
          let supply = liquidity + lpTotalsupp
          let shareOfPool = liquidity * 100 / supply
          shareOfPool = shareOfPool < 0.01 ? '<0.01' : shareOfPool
          console.log("shareOfPool", shareOfPool, 'addliquidity', liquidity, lpTotalsupp, 'totalliquidity', supply)
          setPoolShare(shareOfPool)
          setgetLiquidity(liquidity)
        }
        else {
          // liquidity = Math.sqrt(amount0.mul(amount1)).sub(MINIMUM_LIQUIDITY);
          let liquidity = Math.sqrt((parseFloat(fromValue) * parseFloat(toValue)) - MINIMUM_LIQUIDITY);
          console.log("liquidity", liquidity)
          liquidity =  isNaN(liquidity) ? 0 : liquidity
          setPoolShare(100)
          setgetLiquidity(liquidity)
        }
      }
    } catch (err) {
      console.log(err, 'priceCalculation__err')
    }
  }

  const onChange = (e) => {
    try {
      const { name, value } = e.target;
      if (name == "tokenAval") {
        if (value == 0) {
          setToTokenper('')
          setFromTokenper('')
        }
        var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
        if (!numbers.test(e.target.value) && e.target.value !== "") {
          return false
        }
        console.log("value_onchange", value)
        if (pairAddress !== ZEROTH_ADDRESS) {
          let price
          console.log("pairInfo", reserveB, reserveA)
          if (tokenA?.address == token0) {
            price = toFixedNumber(reserveB / reserveA)
          }
          else {
            price = toFixedNumber(reserveA / reserveB)
          }

          let amount1 = value * price;
          console.log("amount1", amount1, price, amount1 > tokenB.balance)
          amount1 = isNaN(amount1) ? 0 : amount1
          setTokenBval(amount1)
          priceCalculation(value, amount1)
          setTokenAval(value)
          return true
        }
        console.log("value_onchange1", value)
        setTokenAval(value)
        priceCalculation(value, tokenBval)
      }
      if (name == "tokenBval") {
        if (value == 0) {
          setToTokenper('')
          setFromTokenper('')
        }
        var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
        if (!numbers.test(e.target.value) && e.target.value !== "") {
          return false
        }
        let price

        if (pairAddress !== ZEROTH_ADDRESS) {
          if (tokenB?.address == token1) {
            price = toFixedNumber(reserveA / reserveB)
          }
          else {
            price = toFixedNumber(reserveB / reserveA)
          }

          let amount0 = value * price;
          amount0 = isNaN(amount0) ? 0 : amount0
          console.log("amount0", amount0, price)
          setTokenAval(amount0)
          priceCalculation(amount0, value)
          return true
        }
        setTokenBval(value)
        priceCalculation(tokenAval, value)
      };
    } catch (err) {
      console.log(err, "onChange__errr")
    }
  }

  //get func 
  const isPoolCheck = async (tokenA, tokenB) => {
    try {
      if (!isEmpty(tokenA) && !isEmpty(tokenB)) {
        let lpAddress = await GetPairaddress(tokenA.address, tokenB.address)
        getpair(tokenA, tokenB)
        setlpAddress(lpAddress)
        if (lpAddress != ZEROTH_ADDRESS) {
          let poolAddress = await GetPool(lpAddress)
          console.log(poolAddress, 'isPoolCheck1')
          setpoolAddress(poolAddress)
        }
      }
    } catch (err) {
      console.log('isPoolCheck__err', err)
    }
  }

  const getpair = async (tokenA, tokenB) => {
    let lpAddress = await GetPairInfo(tokenA?.address, tokenB?.address)
    console.log("getpairaddress", lpAddress)
    setPairAddress(lpAddress)
    var bal = await Balance(lpAddress)
    console.log("getpairaddress__bal", lpAddress)
    setlpBalance(bal)
    let calls = [{
      address: lpAddress,
      name: 'getReserves',
    },
    {
      address: lpAddress,
      name: 'totalSupply'
    },
    {
      address: lpAddress,
      name: 'token0'
    },
    {
      address: lpAddress,
      name: 'token1'
    }
    ]

    var pooldata = await multicall(pair_ABI, calls)
    console.log(pooldata, 'getpairaddress1')
    if (pooldata) {
      let poolA = new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18
      let poolB = new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18
      console.log(lpAddress !== ZEROTH_ADDRESS, 'getpairaddress3')
      if (lpAddress !== ZEROTH_ADDRESS) {
        setSwapeRate0(poolB / poolA)
        setSwapeRate1(poolA / poolB)
        console.log(!isEmpty(tokenAval) && !isNaN(tokenAval), 'getpairaddress2')
        if (!isEmpty(tokenAval) && !isNaN(tokenAval)) {
          console.log(tokenAval, "getpairaddress4", poolB, poolA)
          let price
          if (tokenA?.address == pooldata[2][0]) {
            price = poolB / poolA
          } else {
            price = poolA / poolB
          }
          let amount1 = tokenAval * price

          console.log('getpairaddress4', amount1, price)
          setTokenBval(amount1)
          priceCalculation(tokenAval, amount1)
        }
        else if (!isEmpty(tokenBval) && !isNaN(tokenBval)) {
          console.log(tokenBval, "toValue_getpair")
          let price
          if (tokenB?.address == pooldata[3][0]) {
            price = toFixedNumber(poolA / poolB)
          }
          else {
            price = toFixedNumber(poolB / poolA)
          }
          let amount2 = tokenBval * price
          setTokenAval(amount2)
          priceCalculation(amount2, tokenBval)
        }
      } else {
        setTokenAval('')
        setTokenBval('')
        setToTokenper('')
        setFromTokenper('')
      }
      setReserveA(new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18)
      setReserveB(new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18)
      console.log("pooldata", pooldata, new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18, new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18, pooldata[2][0], pooldata[3][0])
      console.log("share", new BigNumber(pooldata[1][0]._hex).toNumber() / 10 ** 18)
      setLpTotalsupp(new BigNumber(pooldata[1][0]._hex).toNumber() / 10 ** 18)
      setToken0(pooldata[2][0])
      setToken1(pooldata[3][0])

    }

  }

  const fromMax = () => {
    console.log("fromMax", tokenA?.balance)
    setTokenAval(tokenA?.balance)
    if (pairAddress !== ZEROTH_ADDRESS) {
      let price
      console.log("pairInfo", reserveA, reserveB)
      if (tokenA?.address == token0) {
        price = toFixedNumber(reserveB / reserveA)
      }
      else {
        price = toFixedNumber(reserveA / reserveB)
      }

      let amount1 = tokenA?.balance * price;
      console.log("amount1", amount1, price)
      setTokenBval(amount1)
      priceCalculation(tokenA?.balance, amount1)
      return true
    }
    priceCalculation(tokenA.balance, tokenBval)
  }

  const toMax = () => {

    setTokenBval(tokenB?.balance)
    if (pairAddress !== ZEROTH_ADDRESS) {
      let price
      if (tokenB?.address == token1) {
        price = toFixedNumber(reserveA / reserveB)
      }
      else {
        price = toFixedNumber(reserveB / reserveA)
      }
      let amount0 = tokenB?.balance * price;
      console.log("amount0", amount0, price)
      setTokenAval(amount0)
      priceCalculation(amount0, tokenB.balance)
      return true
    }
    priceCalculation(tokenAval, tokenB.balance)
  }



  //submit func
  const InitializePool = async () => {
    try {
      let result = await CreatePool(lpAddress)
      console.log(result, 'InitializePool')
      if (result) {
        let poolAddress = await GetPool(lpAddress)
        console.log(poolAddress, 'InitializePool1')
        setpoolAddress(poolAddress)
      }
    } catch (err) {
      console.log('InitializePool__err', err)
    }
  }

  const TokenAapprove = async () => {
    try {
      let tokenAapprove = await ApproveToken(getPositionHelperAddress(), MAX_UNIT256, getWalletAddress(), tokenA.address)
      setTokenAapprove(tokenAapprove)
    } catch (err) {
      console.log(err, 'TokenAapprove__Err')
      setTokenAapprove(false)
    }
  }

  const TokenBapprove = async () => {
    try {
      let tokenBapprove = await ApproveToken(getPositionHelperAddress(), MAX_UNIT256, getWalletAddress(), tokenB.address)
      setTokenBapprove(tokenBapprove)
    } catch (err) {
      console.log(err, 'TokenAapprove__Err')
      setTokenBapprove(false)
    }
  }


  const AddLiquidity = () => {
    try {
      let obj = {
        fromToken: tokenA,
        toToken: tokenB,
        key:'firstadd'
      }
      navigate('/add',{state:obj})
    } catch (err) {
      console.log(err, 'AddLiquidity__err')
    }
  }

  let obj = {
    toTokenper: toTokenper,
    fromTokenper: fromTokenper,
    tokenA: tokenA,
    tokenB: tokenB,
    SwapeRate0: SwapeRate0,
    SwapeRate1: SwapeRate1,
    lpAddress: lpAddress,
    poolAddress: poolAddress,
    pairAddress: pairAddress,
    poolShare: poolShare,
    tokenAval: tokenAval,
    tokenBval: tokenBval,
    reserveA: reserveA,
    reserveB: reserveB,
    addliquidity: getLiquidity
  }

  return (
    <>
      <Header />
      <div className={`${cascading.innerpages} ${cascading.swappage}`}>
        <div className={`container`}>
          <div className={`row justify-content-center ${cascading.swapcardrow}`}>
            <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 `}>
              <div className='text-end mb-4'>
                <Link to="/add" className={`btn ${cascading.currencybtn} ${cascading.btnrightpostion}`}>Add Liquidity</Link>
              </div>
              <div className={` ${cascading.cardstyle}`}>
                <div className={`${cascading.toprow}`}>
                  <div>
                    <p className={`${cascading.headings}`}>Add Liquidity</p>
                    <p className={`${cascading.desc}`}>Stable</p>
                  </div>
                  <div className={`${cascading.iconsec}`}>
                    <IoMdSettings onClick={() => setSettingshow(true)} />
                    <MdHistory onClick={() => setRecentshow(true)} />
                  </div>
                </div>
                <div className={`${cascading.inputsec} mb-4`}>
                  <div className={`${cascading.balancerow}`}>
                    <p className={`${cascading.inputlabel}`}>From</p>
                    {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) && <p className={`${cascading.inputlabel}`}>Balance : {isEmpty(tokenA) ? 0 : roundToSignificant(tokenA.balance, 6)}</p>}
                  </div>

                  <div className={`${cascading.inputsecrow}`}>
                    <div >
                      <input type="text" name='tokenAval' value={tokenAval} className={`from-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0.0" onChange={onChange} />
                    </div>
                    <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                      <button
                        className={`btn ${cascading.currencybtn}`}
                        onClick={() => {
                          fromMax()
                        }}
                      >
                        Max
                      </button>
                      <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshow(true)}>
                        {isEmpty(tokenA) ? "" :
                          <img src={fileObjectUrl(tokenA?.image)} alt='bnb' className={`img-fluid ${cascading.coinimg}`} />}
                        {!isEmpty(tokenA) ? tokenA.symbol : "Select a currency"} <FaAngleDown fill='#fff' />
                      </button>
                    </div>


                  </div>

                </div>
                <div className={`row ${cascading.arrowrow}`}>
                  <div className={`${cascading.swaparrow}`}>
                    <FaPlus />
                  </div>
                </div>
                <div className={`${cascading.inputsec} mb-4`}>
                  <div className={`${cascading.balancerow}`}>
                    <p className={`${cascading.inputlabel}`}>To</p>
                    {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) && <p className={`${cascading.inputlabel}`}>Balance : {isEmpty(tokenB) ? 0 : roundToSignificant(tokenB.balance, 6)}</p>}
                  </div>

                  <div className={`${cascading.inputsecrow}`}>
                    <div >
                      <input type="text" name='tokenBval' value={tokenBval} className={`from-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0.0" onChange={onChange} />
                    </div>

                    <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                      <button
                        className={`btn ${cascading.currencybtn}`}
                        onClick={() => {
                          toMax()
                        }}
                      >
                        Max
                      </button>
                      <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshow2(true)}>
                        {isEmpty(tokenB) ? ''
                          :
                          <img src={fileObjectUrl(tokenB.image)} alt='bnb' className={`img-fluid ${cascading.coinimg}`} />
                        }
                        {isEmpty(tokenB) ? "Select currency" : tokenB.symbol}
                        <FaAngleDown fill='#fff' />
                      </button>
                    </div>


                  </div>

                </div>
                <div className={`${cascading.createaccordion} mb-4`}>

                  {!isEmpty(tokenA) && !isEmpty(tokenB) ?
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey={0}>
                        <Accordion.Header>
                          <div className={`mb-0 text-start pt-1 ${cascading.labelflex}`}>
                            <p className={`mb-3 text-start ${cascading.label}`}>
                              1 {tokenA.symbol} = {roundToSignificant(toTokenper, 6)} {tokenB.symbol}
                              {/* <span className='ps-1'>($2.87)</span> */}
                            </p>
                            <p className={`mb-0 text-start ${cascading.label}`}>1 {tokenB.symbol} = {roundToSignificant(fromTokenper, 6)} {tokenA.symbol}
                              {/* <span className='ps-1'>($3,251.56)</span> */}
                            </p>

                          </div>
                        </Accordion.Header>

                        <Accordion.Body className={`pb-1`}>
                          <div className={``}>
                            <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                              {/* <div className={`${cascading.secrow}`}>
                                <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Pair Type

                                </p>
                                <p className={`mb-0 ${cascading.labels}`}>Volatile
                                </p>
                              </div> */}

                              {pairAddress != ZEROTH_ADDRESS ?
                                <>
                                  <div className={`${cascading.secrow} mt-3`}>
                                    <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>{tokenA.symbol} Swap Rate

                                    </p>
                                    <p className={`mb-0 ${cascading.labels}`}>1 {tokenA.symbol} <FaArrowRight /> {roundToSignificant(SwapeRate0, 6)} {tokenB.symbol}
                                    </p>
                                  </div>
                                  <div className={`${cascading.secrow} mt-3`}>
                                    <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>{tokenB.symbol} Swap Rate

                                    </p>
                                    <p className={`mb-0 ${cascading.labels}`}>1 {tokenB.symbol} <FaArrowRight /> {roundToSignificant(SwapeRate1, 6)} {tokenA.symbol}
                                    </p>
                                  </div>
                                </>
                                : ""}

                              <div className={`${cascading.secrow} mt-3`}>
                                <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>{tokenA.symbol}/{tokenB.symbol} liquidity ratio

                                </p>
                                <p className={`mb-0 ${cascading.labels}`}>{roundToSignificant(toTokenper, 6)}
                                </p>
                              </div>

                              <div className={`${cascading.secrow} mt-3`}>
                                <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>{tokenB.symbol}/{tokenA.symbol} liquidity ratio

                                </p>
                                <p className={`mb-0 ${cascading.labels}`}>{roundToSignificant(fromTokenper, 6)}
                                </p>
                              </div>

                              <div className={`${cascading.secrow} mt-3`}>
                                <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Pool Share

                                </p>
                                <p className={`mb-0 ${cascading.labels}`}>{poolShare} %
                                  {/* <FaChevronLeft /> */}
                                </p>
                              </div>

                              <div className={`${cascading.secrow} mt-3`}>
                                <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>LP Address

                                </p>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <p className={`mb-0 ${cascading.labels}`}>
                                    {`${(lpAddress).substring(0, 5)}...${lpAddress.substring(38, 42)}`}
                                  </p>

                                  <CopyToClipboard
                                    text={lpAddress}
                                    onCopy={() =>
                                      toast.success('Address copied')
                                      // toast('Address copied', {
                                      //   duration: 2000,
                                      //   position: 'top-right',

                                      //   // Styling
                                      //   style: { background: "#1c2029", color: "#fff", minWidth: 200 },
                                      //   className: '',

                                      //   // Custom Icon

                                      //   // Change colors of success/error/loading icon
                                      //   iconTheme: {
                                      //     primary: '#000',
                                      //     secondary: '#fff',
                                      //   },

                                      //   // Aria
                                      //   ariaProps: {
                                      //     role: 'status',
                                      //     'aria-live': 'polite',
                                      //   },
                                      // })
                                    }

                                  >
                                    <button className={`mb-0 ${cascading.copybtntrans}`}><FaCopy /></button>
                                  </CopyToClipboard>
                                </div>


                              </div>

                              {poolAddress !== ZEROTH_ADDRESS ?
                                <div className={`${cascading.secrow} mt-3`}>
                                  <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>NFT Pool Address

                                  </p>
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <p className={`mb-0 ${cascading.labels}`}>
                                      {`${(poolAddress).substring(0, 5)}...${poolAddress.substring(38, 42)}`}
                                      {/* {poolAddress} */}
                                    </p>

                                    <CopyToClipboard
                                      text={poolAddress}
                                      onCopy={() =>
                                        toast('Address copied', {
                                          duration: 2000,
                                          position: 'top-right',

                                          // Styling
                                          style: { background: "#1c2029", color: "#fff", minWidth: 200 },
                                          className: '',

                                          // Custom Icon

                                          // Change colors of success/error/loading icon
                                          iconTheme: {
                                            primary: '#000',
                                            secondary: '#fff',
                                          },

                                          // Aria
                                          ariaProps: {
                                            role: 'status',
                                            'aria-live': 'polite',
                                          },
                                        })
                                      }

                                    >
                                      <button className={`mb-0 ${cascading.copybtntrans}`}><FaCopy /></button>
                                    </CopyToClipboard>
                                  </div>
                                </div> : ""}


                            </div>

                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                    </Accordion>
                    : ""}
                </div>
                {pairAddress == ZEROTH_ADDRESS ?
                  <div className={`mt-3 pb-1 ${cascading.cardstyle}`}>
                    <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                      <div className={`${cascading.secrow}`}>
                        <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>
                          You are the first liquidity provider! The token ratio that you choose here will set the price on this pool.
                        </p>
                      </div>
                    </div>
                  </div>
                  : ''}
                {poolAddress == ZEROTH_ADDRESS ?
                  <div className={`mt-3 pb-1 ${cascading.cardstyle}`}>
                    <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                      <div className={`${cascading.secrow}`}>
                        <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>
                          You are the very first spNFT minter for this asset! You will need to initialize the spNFT contract first.
                        </p>
                      </div>
                    </div>
                  </div>
                  : ''}
                <div className={`${cascading.btnsec}`}>
                  {userdetail?.walletStatus !== 'connect' && isEmpty(getWalletAddress()) ?
                    <button className={`btn ${cascading.connect_btn}`} onClick={() => setWalletmodalshow(true)}>Connect Wallet</button> :
                    pairAddress == ZEROTH_ADDRESS ?
                      <button
                        className={`btn ${cascading.connect_btn}`}
                        onClick={() => { AddLiquidity() }}>
                        Add Liquidity
                      </button> :
                      poolAddress == ZEROTH_ADDRESS ?
                        <button
                          className={`btn ${cascading.connect_btn}`}
                          onClick={() => { InitializePool() }}>
                          Initialize
                        </button>
                        :
                        !isEmpty(tokenA) && !tokenAapprove ?

                          <button
                            className={`btn ${cascading.connect_btn}`}
                            onClick={() => { TokenAapprove() }}
                          >
                            {`Enable ${tokenA.symbol}`}
                          </button> :
                          !isEmpty(tokenB) && !tokenBapprove ?
                            <button
                              className={`btn ${cascading.connect_btn}`}
                              onClick={() => { TokenBapprove() }}
                            >
                              {`Enable ${tokenB.symbol}`}
                            </button> :
                            isEmpty(tokenBval) || isEmpty(tokenAval) ?
                              <button
                                className={`btn ${cascading.connect_btn}`}
                                disabled={true}

                              >
                                Enter amount
                              </button> :
                              tokenBval > tokenB.balance || tokenAval > tokenA.balance ?
                                <button
                                  className={`btn ${cascading.connect_btn}`}
                                  disabled={true}
                                >
                                  {tokenAval > tokenA.balance ? `Insufficient ${tokenA.symbol} balance` : tokenBval > tokenB.balance ? `Insufficient ${tokenB.symbol} balance` : ''}
                                </button>
                                :
                                <button
                                  className={`btn ${cascading.connect_btn}`}
                                  onClick={() => { setLiquiditymodal(true) }}
                                  disabled={isEmpty(poolAddress) ? true : false}
                                >
                                  Create Position
                                </button>}
                </div>
              </div>

              {/* <div className={`mt-3 pb-1 ${cascading.cardstyle}`}>
                <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                  <div className={`${cascading.secrow}`}>
                    <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Minimum Received
                      <OverlayTrigger
                        className={`${cascading.tooltip_style}`}
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.
                          </Tooltip>
                        }
                      >
                        <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                      </OverlayTrigger>
                    </p>
                    <p className={`mb-0 ${cascading.labels}`}>0.00372 DB
                    </p>
                  </div>

                  <div className={`mt-3 ${cascading.secrow}`}>
                    <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Price Impact
                      <OverlayTrigger
                        className={`${cascading.tooltip_style}`}
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            The difference between the market price and estimated price due to trade size.
                          </Tooltip>
                        }
                      >
                        <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                      </OverlayTrigger>
                    </p>
                    <p className={`mb-0 ${cascading.labels}`}>45.07%
                    </p>
                  </div>


                  <div className={`mt-3 ${cascading.secrow}`}>
                    <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Liquidity Provider Fee
                      <OverlayTrigger
                        className={`${cascading.tooltip_style}`}
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            <p className='mb-1'>For each trade a 0.25% fee is paid</p>
                            <p className='mb-1'>- 0.17% to LP token holders</p>
                            <p className='mb-1'>- 0.03% to the Treasury</p>
                            <p className='mb-1'>- 0.05% towards DSP buyback and burn</p>


                          </Tooltip>
                        }
                      >
                        <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                      </OverlayTrigger>
                    </p>
                    <p className={`mb-0 ${cascading.labels}`}>0.00372124456 BNB
                    </p>
                  </div>
                </div>

              </div> */}
            </div>

          </div>


        </div>


      </div>



      <Footer />
      {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
      {recentshow && <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />}
      {tokenshow && <Selecttoken show={tokenshow} SetToken={(data) => { onFromTokenChange(data) }} commontoken={commontoken} onHide={() => { setTokenshow(false); setCommontoken(false) }} />}
      {tokenshow2 && <Selecttoken show={tokenshow2} SetToken={(data) => { console.log(data, "onToTokenChange"); onToTokenChange(data) }} commontoken={commontoken} onHide={() => { setTokenshow2(false); setCommontoken(false) }} />}
      {settingshow && <Settingsmodal show={settingshow} onHide={() => setSettingshow(false)} />}
      {swapmodal && <ConfirmSwapmodal show={swapmodal} onHide={() => setSwapmodal(false)} />}
      {liquiditymodal &&
        <Addliquiditymodal
          show={liquiditymodal}
          showsucces={(text) => {
            setloaderText(text)
            setsuccessLoader(false)
            setmodalsuccess(true);
            setLiquiditymodal(false);
          }}
          data={obj}
          onHide={() => {
            setLiquiditymodal(false);
          }}
          SetsuccessLoader={(text, hash) => {
            setTrxn(hash)
            setsuccessText(text);
            setsuccessLoader(true)
            SetInitalData()
          }}
          setError={(text) => {
            seterrorText(text);
            setsuccessLoader(undefined)
          }}
        // successText={(text) => {  }}
        // loaderText={(text) => { setloaderText(text) }}
        />}
      {modalsucess &&
        <Addsuccessmodal
          show={modalsucess}
          title='Add Liquidity'
          loaderText={loaderText}
          successText={successText}
          isLoader={successLoader}
          lpSymbol={`${tokenA.symbol}-${tokenB.symbol}`}
          hash={Trxn}
          errorText={errorText}
          onHide={() => { setmodalsuccess(false); }}
        />
      }
    </>
  )
}
