import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";




import { IoMdClose } from "react-icons/io";

import { useSelector } from 'react-redux';

//import
import { getTokenPricesFromFarm } from '../hooks/usePools';
import { pendingDividendsAmount } from '../hooks/useDividends';
import { roundToSignificant } from '../hooks/useCommon';
import { isEmpty } from '../lib/isEmpty';
export default function Dividentcalculatormodal(props) {

  const { xtokenbalance } = useSelector((state) => (state.xtoken))
  const { dividendsdetails, userInfo, distributeInfo } = useSelector((state) => (state.dividends))
  const [dividentcalculatormodal, setdividentCalculatormodal] = useState(true);
  const [daiyReturns, setdaiyReturns] = useState('0')
  const [perReturns, setperReturns] = useState('0')
  const [totalAllocate, settotalAllocate] = useState('');
  const [totalShare, settotalShare] = useState('');
  const [amount, setAmount] = useState('');

  const calculateDailyReturns = async (amount) => {
    try {
      let daiyReturns = 0
      let perReturns = 0
      let farms = JSON.parse(localStorage.getItem("Farms"))
      let price = await getTokenPricesFromFarm(farms)
      for (let i = 0; i < distributeInfo.length; i++) {
        let dividendsInfo_ = distributeInfo[i]
        let tokenInusd = isEmpty(price[dividendsInfo_.tokenAddress.toLowerCase()]) ?  1 : price[dividendsInfo_.tokenAddress.toLowerCase()]
        let users = userInfo.find((val) => (val.tokenAddress == dividendsInfo_.tokenAddress))
        let nextCycleStartTime = dividendsdetails.nextCycleStartTime
        let cycleDurationSeconds = dividendsdetails.cycleDurationSeconds
        let totalAllocation = parseFloat(dividendsdetails.totalAllocation * 10 ** 18) + (amount * 10 ** 18)
        let usersAllocation = parseFloat(dividendsdetails.usersAllocation * 10 ** 18) + parseFloat(amount * 10 ** 18)
        let pendingamount = await pendingDividendsAmount(dividendsInfo_, users, usersAllocation, nextCycleStartTime, cycleDurationSeconds, totalAllocation)
        console.log(pendingamount, 'calculateDailyReturns', dividendsInfo_.tokenAddress)

        pendingamount = pendingamount / 10 ** 18
        let perReturn = pendingamount / (usersAllocation / 10 ** 18)
        console.log(pendingamount, perReturn, 'calculateDailyReturns')
        perReturns = parseFloat(perReturns) + (parseFloat(perReturn) * parseFloat(tokenInusd))
        daiyReturns = parseFloat(daiyReturns) + (parseFloat(pendingamount) * parseFloat(tokenInusd))
        console.log(pendingamount, perReturn, daiyReturns, 'calculateDailyReturns', dividendsInfo_.tokenAddress)
      }
      console.log(daiyReturns, 'daiyReturns')
      if (daiyReturns <= 0) {
        setdaiyReturns(0)
      } else {
        setdaiyReturns(daiyReturns)
      }
      if (perReturns <= 0) {
        setperReturns(0)
      } else {
        setperReturns(perReturns)
      }
    } catch (err) {
      console.log(err, 'calculateDailyReturns__err')
    }
  }

  useEffect(() => {
    if (dividendsdetails.usersAllocation) {
      settotalAllocate(dividendsdetails.usersAllocation)
      let share = (dividendsdetails.usersAllocation / dividendsdetails.totalAllocation) * 100
      settotalShare(share)
    }
  }, [dividendsdetails])

  const Max = () => {
    try {
      setAmount(xtokenbalance)
      ShareAllocation(xtokenbalance)
      calculateDailyReturns(xtokenbalance)
    } catch (err) {
      console.log(err, 'Max__err')
    }

  }

  const ShareAllocation = (value) => {
    try {
      let total = parseFloat(value) + parseFloat(dividendsdetails.usersAllocation)
      settotalAllocate(total)
      console.log('ShareAllocation', value)
      let totalAllocation = parseFloat(value) + parseFloat(dividendsdetails.totalAllocation)
      let share = (total / totalAllocation) * 100
      settotalShare(share)
    } catch (err) {
      console.log(err, 'ShareAllocation_err')
    }
  }


  const onChange = (e) => {
    try {
      const { name, value } = e.target;
      if (name == "amount") {
        var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
        if (!numbers.test(e.target.value) && e.target.value !== "") {
          return false
        }
        setAmount(value)
        if (value == '') {
          ShareAllocation(0)
        } else {
          ShareAllocation(value)
          calculateDailyReturns(value)
        }
      }
    } catch (err) {
      console.log(err, 'onChange_err')
    }
  };
  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={dividentcalculatormodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Dividends Calculator</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>
              <p className={`${cascading.innerheadwhite}`}>YUM Allocation
              </p>

              <div className={`${cascading.inputsec} my-3`}>
                <div className={`input-group mb-3`}>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="0"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name='amount'
                    value={amount}
                    onChange={onChange}
                  />
                  <div className={`input-group-append`} >
                    <button
                      className={` ${cascading.inputspan}`} id="basic-addon1"
                      onClick={() => (Max())}
                    >
                      Max
                    </button>
                  </div>
                </div>
                <p className={`${cascading.balsec}`}>balance : {roundToSignificant(xtokenbalance, 6)} YUM</p>
              </div>
              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocated amount</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(totalAllocate, 6)} YUM</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocation share</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{totalShare}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Daily returns/YUM</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>${roundToSignificant(perReturns, 4)}</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total daily returns</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>${roundToSignificant(daiyReturns, 4)}</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deallocation fee</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>{dividendsdetails.deallocationFee} %</p>
                </div>
              </div>
              <Button className={`${cascading.btnstyle} mt-4`} onClick={props.onHide}>
                Close
              </Button>

            </div>

            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}
          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

