import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";

import { Duration } from '../lib/DateTimeHelper';
import { consolelog } from '../lib/consolelog';
import { isEmpty } from '../lib/isEmpty';
import { useSelector } from 'react-redux';
import { toFixedNumber } from '../lib/FixedNumber';
import { IoMdClose } from "react-icons/io";
import { useParams } from 'react-router';
import { GetBounsApr,GetMultiplierByBoostPoints ,getApy} from '../hooks/useNFTpool';
import { GetPoolTotalAllocation,GettotalAllocationFloor ,GetMaxBonus} from '../hooks/useyeildBooster';
import { roundToSignificant } from '../hooks/useCommon';
export default function Apycalculatormodal(props) {

  const [Apy,setApy] =useState('')
  const [BonusApr, setBonusApr] = useState('0')
  const [totalApr, settotalApr] = useState('0')
  const [apycalculatormodal, setapyCalculatormodal] = useState(true);
  const [poolInfo, setPoolInfo] = useState({})
  const [Lockduration, setLockDuration] = useState(0)
  const [incDisable, setincDisable] = useState(false)
  const [DecDisable, setDecDisable] = useState(false)
  const [days, Setdays] = useState('0')
  const [months, Setmonths] = useState('0')
  const [lockPerc, setLockPerc] = useState('0')
  const [BoostPerc, setBoostPerc] = useState('0')
  const [amount, setAmount] = useState('')
  const [Bounsamount, setBounsamount] = useState('')
  const [depositValinusd, setdepositValinusd] = useState('0')
  const [projectEnarings, setprojectEnarings] = useState('0')
  const { poolAddress } = useParams()
  const { xtokenbalance } = useSelector((state) => (state.xtoken))

  const { nftpooldata, poolrewards } = useSelector((state) => (state.nftpool))
  useEffect(() => {
    let PoolInfo = nftpooldata?.find((val) => (val?.poolAddress == poolAddress))
    setPoolInfo(PoolInfo)
  }, [nftpooldata, poolAddress])

  useEffect(()=>{
    CalculateBonusApr()
  },[lockPerc,BoostPerc,poolInfo])

  useEffect(()=>{
    apy()
    ProjectedEnarings(depositValinusd)
  },[totalApr])
  const apy = () => {
    let autoCompoundFrequency = 0
    let performanceFee = 0
    console.log((totalApr, autoCompoundFrequency > 0 ? autoCompoundFrequency : 1, 365, performanceFee) * 100,'apy')
    let APY = getApy((totalApr, autoCompoundFrequency > 0 ? autoCompoundFrequency : 1, 365, performanceFee) * 100).toFixed(2)
    console.log("APY", APY)
    setApy(APY)
  }
  const CalculateBonusApr = () => {
    try {
      let bonusPercentage = parseFloat(lockPerc) + parseFloat(BoostPerc)
      let BonusApr = GetBounsApr({
        annualInterestRate: poolInfo.FramBaseApr,
        bonusPercentage: bonusPercentage
      })
      BonusApr = isNaN(BonusApr) ? 0 : BonusApr
      setBonusApr(BonusApr)
      let totalApr = parseFloat(BonusApr) + parseFloat(poolInfo.FramBaseApr) + parseFloat(poolInfo.SwapFeeApr)
      console.log(totalApr,'CalculateBonusApr')
      settotalApr(totalApr)
    } catch (err) {
      console.log('CalculateBonusApr__err')
    }
  }



  //change func
  const inCreaseDuration = () => {
    try {
      let Time = Lockduration
      let interval = 1 * 86400
      Time = Time + interval
      console.log(!(Time > poolInfo.maxLockDuration), Time, poolInfo.maxLockDuration, 'inCreaseDuration')
      if (!(Time > poolInfo.maxLockDuration)) {
        if (Time == poolInfo.maxLockDuration) {
          setincDisable(true)
        }
        let days = Duration(Time, 'days')
        let lockPercn = (Time / poolInfo.maxLockDuration) * 100
        setLockPerc((lockPercn).toFixed(2))
        days = days % 30
        if (days == 0) {
          Setmonths(parseFloat(months) + 1)
          Setdays(0)
        }
        else {
          Setdays(parseFloat(days))
        }
        setDecDisable(false)
        setLockDuration(Time)
      }

    } catch (err) {
      consolelog('inCreaseDuration__err', err, true)
    }
  }

  const DeCreaseDuration = async () => {
    try {
      let Time = Lockduration
      let interval = 1 * 86400
      Time = Time - interval
      if (!(Time < 0)) {
        if (Time == 0) {
          setDecDisable(true)
        }
        let lockPercn = (Time / poolInfo.maxLockDuration) * 100
        setLockPerc((lockPercn).toFixed(2))
        let Days = Duration(Lockduration, 'days')
        Days = Days % 30
        if (Days == 0) {
          Setmonths(parseFloat(months) - 1)
          Setdays(29)
        }
        else {
          Setdays(parseFloat(Days) - 1)
        }
        setincDisable(false)
        setLockDuration(Time)
      }
    } catch (err) {
      consolelog('DeCreaseDuration__err', err, true)
    }
  }

  const maxLockDuration = () => {
    try {
      let months = Duration(poolInfo.maxLockDuration, 'months')
      let days = Duration(poolInfo.maxLockDuration, 'days')
      console.log(months, days, 'maxLockDuration')
      let lockPercn = (poolInfo.maxLockDuration / poolInfo.maxLockDuration) * 100
      setLockPerc((lockPercn).toFixed(2))
      days = days % 30
      Setdays(days)
      Setmonths(months)
      setLockDuration(poolInfo.maxLockDuration)
      setincDisable(true)
      setDecDisable(false)

    } catch (err) {
      consolelog('maxLockDuration__err', err, true)
    }
  }

  const handleChange = (e) => {
    try {
      const { name, value } = e.target
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (!numbers.test(value) && value !== "") {
        return false
      }
      if (name == 'amount') {
        setAmount(value)
        if (value != '') {
          let depositValinusd = isEmpty(poolInfo.lpPriceInusd) ? 0 : parseFloat(value) * parseFloat(poolInfo.lpPriceInusd)
          setdepositValinusd(depositValinusd)
          ProjectedEnarings(depositValinusd)
        }
      }
      if(name == 'Bounsamount'){
        setBounsamount(value)
        setExpectMultipler(value)
      }
    } catch (err) {
      console.log(err, 'onChange__err')
    }
  }


  const SetMax = () => {
    try {
      setAmount(poolInfo.lpBalance)
      let depositValinusd = isEmpty(poolInfo.lpPriceInusd) ? 0 : parseFloat(poolInfo.lpBalance) * parseFloat(poolInfo.lpPriceInusd)
      setdepositValinusd(depositValinusd)
    } catch (err) {
      console.log(err, 'SetMax__err')
    }
  }

  const SetMaxBouns = () => {
    try {
      setBounsamount(xtokenbalance)
      setExpectMultipler(xtokenbalance)
    } catch (err) {
      console.log(err, 'SetMax__err')
    }
  }

  const SetBonusMax = async () => {
    try {
        // let index = allocateDetails.findIndex((val) => (val.tokenId == record.tokenId && val.poolAddress == record.poolAddress))

        // if (index == -1) {
            console.log(poolAddress,'SetMaxBonus__poolAddress')
            let poolAllocation = await GetPoolTotalAllocation(poolAddress)
            poolAllocation = parseFloat(poolAllocation)
            let lpSupply = parseFloat(poolInfo.lpSupply)
            let multiper = toFixedNumber(poolInfo.maxBoostMultiplier * 10 ** 4)
            let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier * 10 ** 4)
            let lpAmount = toFixedNumber(amount * 10 ** 18)
            let totalAllocationFloor = await GettotalAllocationFloor()
            totalAllocationFloor = parseFloat(totalAllocationFloor)
            let MaxAllocation = GetMaxBonus(multiper, lpSupply, maxBoostMultiplier, lpAmount, poolAllocation, totalAllocationFloor)
            console.log(MaxAllocation, 'SetMaxBonus2')
            // let checkMax = MaxAllocation > xtokenbalance ? xtokenbalance : MaxAllocation
            // console.log(checkMax, 'SetMaxBonus2')
            setBounsamount(MaxAllocation)
            setExpectMultipler(MaxAllocation)
        // }
    } catch (err) {
        console.log(err, 'SetMaxBonus__err')
    }
}
  const setExpectMultipler = async (Bounsamount) => {
    try {
      let lpAmount = toFixedNumber(amount * 10 ** 18)
      lpAmount = lpAmount.toString()
      let boostPoint =parseFloat(Bounsamount)
      boostPoint = toFixedNumber(parseFloat(boostPoint) * 10 ** 18)
      boostPoint = boostPoint.toString()
      console.log('setExpectMultipler', boostPoint, amount)
      let boostmultiplier = await GetMultiplierByBoostPoints(poolAddress, lpAmount, boostPoint)
      // setExmultiper(boostmultiplier)
      setBoostPerc(boostmultiplier * 100)
    } catch (err) {
      console.log(err, 'setExpectMultipler___err')
    }
  }

  const ProjectedEnarings = (depositValinusd)=>{
    try{
      let enarings = depositValinusd *(totalApr/100)
      setprojectEnarings(enarings)
    }catch(err){
      console.log(err,'ProjectedEnarings__err')
    }
  }
  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={apycalculatormodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>APY Calculator</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>
              <p className={`${cascading.innerheadwhite}`}>Deposit USD value
              </p>

              <div className={`${cascading.inputsec} my-3`}>
                <div className={`input-group mb-3`}>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="0"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name = {'amount'}
                    value={amount}
                    onChange={handleChange}
                  />
                  <div className={`input-group-append`} >
                    <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={()=>{SetMax()}}>
                      Max
                    </button>
                  </div>
                </div>
                <p className={`${cascading.balsec}`}>balance : {poolInfo.lpBalance}</p>
              </div>

              <div className='row'>
                <div className='col-12 col-md-5'>
                  <p className={`mb-0 ${cascading.innerheadwhite}`}>Lock duration
                  </p>
                  <a href="javascript:void(0)" className={`${cascading.themelink}`} onClick={()=>{maxLockDuration()}}>Set Max</a>
                </div>
                <div className='col-12 col-md-7 mt-3 mt-md-0'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`} disabled={DecDisable} onClick={()=>{DeCreaseDuration()}}>
                      <FaMinus />
                    </button>
                    <div>
                      <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Months</p>
                      <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >{months}</p>


                    </div>
                    <div>
                      <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Days</p>
                      <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >{days}</p>


                    </div>
                    <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`} disabled={incDisable} onClick={()=>{inCreaseDuration()}}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
              </div>

              <div className='row mt-4'>
                <div className='col-12 col-md-5'>
                  <p className={`mb-0 ${cascading.innerheadwhite}`}>YieldBooster
                  </p>
                  <a href="javascript:void(0)" className={`${cascading.themelink}`} onClick={()=>{SetBonusMax()}}>Get Max Bonus</a>
                </div>
                <div className='col-12 col-md-7 mt-3 mt-md-0'>
                  <div className={`${cascading.inputsec}`}>
                    <div className={`input-group mb-3`}>
                      <input
                        type="text"
                        className={`form-control`}
                        placeholder="0"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name={'Bounsamount'}
                        value={Bounsamount}
                        onChange={handleChange}
                      />
                      <div className={`input-group-append`} >
                        <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={()=>{SetMaxBouns()}}>
                          Max
                        </button>
                      </div>
                    </div>
                    <p className={`${cascading.balsec}`}>balance : {roundToSignificant(xtokenbalance,6)} YUM</p>
                  </div>
                </div>
              </div>
              <p className={`mb-0 ${cascading.innerheadwhite}`}>Estimates
              </p>
              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Projected total APY</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(totalApr,4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Projected yearly earnings</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>${roundToSignificant(projectEnarings,6)}</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo?.SwapFeeApr, 4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo?.FramBaseApr, 6)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm bonus APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(BonusApr, 6)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(totalApr,4)}%</p>
                </div>
              </div>
            </div>

            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}

          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

