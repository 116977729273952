import React, { useState, useEffect } from 'react'
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import cascading from '../assests/css/all.module.css';
import logo from '../assests/images/cardlogo.png'
import Walletmodal from '../modals/walletmodal';
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp, FaAngleLeft, FaMinus, FaPlus, FaRegQuestionCircle, FaCheck, FaUpload, FaLock, FaRocket, FaCoins, FaArrowRight } from "react-icons/fa";

import { useSelector } from 'react-redux';
import pools_icon from '../assests/images/pools_icon.png';

import ReactDatatable from '@ashvin27/react-datatable';



import { Accordion, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';
import { useNavigate } from 'react-router-dom';
import Boostmodal from '../modals/boostmodal';
import Unboostmodal from '../modals/unboostmodal';
import RangeAllocation from './rangeAllocation';

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';
import Addsuccessmodal from '../modals/addsuccessmodal';

//lib
import { isEmpty } from '../lib/isEmpty';
import fileObjectUrl from '../lib/img'
import { roundToSignificant } from '../hooks/useCommon';
import Activenewallocation from './ActivenewAllocation';



export default function Yieldlistadvanced(props) {
    const navigate = useNavigate();
    const [boostmodal, setBoostmodal] = useState(false);
    const [unboostmodal, setUnboostmodal] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);

    const { positionrecord, positionList } = useSelector((state) => (state.position))
    const { allocateDetails } = useSelector((state) => (state.yield))
    const { nftpooldata } = useSelector((state) => (state.nftpool))
    console.log(positionList, 'positionList')
    //   const [isbeared, setIsbeared] = useState(false);
    //   const [islocked, setIslocked] = useState(false);
    //   const [isboosted, setIsboosted] = useState(false);
    const { filter } = props


    const [modalsucess, setModalsucess] = useState(false);


    const [records, setRecords] = useState([])



    const config = {
        button: {
            excel: false,
            print: false,
            extra: false,
        },
        // show_filter:false,
        show_length_menu: false,
        show_pagination: false
    }

    const columns = [
        {
            key: "lpsymbol",
            text: "Token",
            className: "token",
            align: "left",
            sortable: true,
            cell: record => {
                let tokenA = record.token0
                let tokenB = record.token1
                return (
                    <>
                        <div className={`${cascading.imgsec} ${cascading.cursor_pointer_column}`}>
                            <div className={`${cascading.leftimgsec}`}>
                                <img src={isEmpty(tokenA) ? coinlogo : fileObjectUrl(tokenA.image)} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                <img src={isEmpty(tokenB) ? coinlogo : fileObjectUrl(tokenB.image)} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                            </div>
                            <div>
                                <p className={`${cascading.coinname} mb-0`}>{record.lpsymbol}</p>
                                <p className={`${cascading.coinnamegrey} mb-0`}>#ID-{record.tokenId}</p>
                            </div>
                        </div>
                    </>
                );
            }

        },
        {
            key: "amount",
            text: "Amount",
            className: "amount",
            align: "left",
            cell: record => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{roundToSignificant(record.amount, 6)} {record.lpsymbol}</p>
                        </div>
                    </>
                )

            }

        },
        {
            key: "settings",
            text: "Settings",
            className: "settings",
            align: "left",
            sortable: true,
            cell: records => {
                console.log(records, "recordes")
                let islocked = records.lockDuration > 0 ? true : false
                let isboosted = records.boostPoints > 0 ? true : false
                let poolData = nftpooldata.find((val) => (val.poolAddress == records.poolAddress))
                let isbeared = parseFloat(poolData.allocPoint) > 0 ? true : false
                return (
                    <div className='d-flex align-items-center justify-content-start'>
                        <div className='pe-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Yield bearing</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={isbeared ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaCoins /></Button>
                            </OverlayTrigger>
                        </div>

                        <div className='px-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Lock/Unlock position</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={islocked ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaLock /></Button>
                            </OverlayTrigger>
                        </div>

                        <div className='px-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Boost/Unboost position</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={isboosted ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaRocket /></Button>
                            </OverlayTrigger>
                        </div>




                    </div>
                )

            }
        },
        {
            key: "activenewallocation",
            text: "Active Allocation -> New Allocation",
            className: "activenewallocation",
            align: "left",
            cell: record => {
                return (
                    <>
                        <RangeAllocation record={record} />
                        {/* <div className='d-flex align-items-center justify-content-between'>
                            <p className='mb-0 small'>{record.boostPoints} YUM</p>
                            <FaArrowRight />
                            <div className='d-flex align-items-center justify-content-between'>
                                <input className={`${cascading.input_table} form-control form-control-sm`} />
                                <p className='mb-0 ps-2 small'>YUM</p>
                            </div>
                        </div>
                        <input type="range" autoComplete={"off"} className={`form-range ${cascading.rangeinput}`} id="customRange" name="points" min="0" max={checkMax}
                            value={record.boostPoints}
                            onChange={(e) => {
                                points = (record.boostPoints) + parseFloat(e.target.value)
                                console.log(e.target.value, 'rangeOnchange')
                                // rangeOnchange(e.target.value)
                            }}
                        />
                        <RangeSlider
                            className={`${cascading.single_thumb} mt-3`}
                            defaultValue={[0, parseFloat(record.boostPoints)]}
                            thumbsDisabled={[true, false]}
                            rangeSlideDisabled={true}
                            min='0'
                            // value = {[0, parseFloat(record.boostPoints)]}
                            max={parseFloat(checkMax)}
                            onInput={(e)=>{
                                console.log(e,'RangeSlider')
                            }}
                        />
                        <div className='text-end mt-2'>
                            <button className={`${cascading.maxbonusbtn}`}>Get Max Bonus</button>

                        </div> */}

                    </>
                );
            }

        },
        {
            key: "activenewmul",
            text: "Active mul. -> New mul.",
            className: "activenewmul",
            sortable: true,
            width: 80,
            cell: record => {
                return (
                    <Activenewallocation record={record} />
                    // <div className='d-flex align-items-center justify-content-between'>
                    //     <div>
                    //         <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>20.09%</p>
                    //         <p className='mb-0'>x1</p>
                    //     </div>
                    //     <FaArrowRight />
                    //     <div>
                    //         <div>
                    //             <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>26.46%

                    //                 <OverlayTrigger
                    //                     className={`${cascading.tooltip_style}`}
                    //                     key="bottom"
                    //                     placement="bottom"
                    //                     overlay={
                    //                         <Tooltip id={`tooltip-bottom`}>
                    //                             <p className='mb-1'>Swap fees APR: 8.02%</p>
                    //                             <p className='mb-1'>Farm APR: 0.02% - 2.89%</p>
                    //                             <p className='mb-0'>YUM rewards: +3.14%</p>

                    //                         </Tooltip>
                    //                     }
                    //                 >
                    //                     <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                    //                 </OverlayTrigger>
                    //             </p>
                    //             <p className='mb-0'>x2</p>
                    //         </div>
                    //     </div>
                    // </div>
                    // <img src={tick} alt='tick' className={`img-fluid`} width={17} height={17}/>
                )

            }
        },


        {
            key: "action",
            text: "",
            className: "action",
            sortable: true,
            align: "left",
            cell: record => {
                let findData = allocateDetails.find((val) => (val.tokenId == record.tokenId && val.poolAddress == record.poolAddress))
                let isenable = isEmpty(findData) ? false : true
                return (
                    // <div className='d-flex align-items-center justify-content-around'>

                    <div className='d-flex align-items-center justify-content-around'
                    // onClick={() => { console.log("setmodalcoming"); setmodalcoming(true) }}
                    >

                        <button className={isenable ? 'btn_trans_status_table' : 'btn_trans_status_table disabled_btn_apply'}>
                            <FaCheck /> Apply
                        </button>


                    </div>
                )

            }
        },


    ];
    useEffect(() => {
        if (!isEmpty(positionList)) {
            if (filter == 'Boosted') {
                BoostedOnly([...positionList])
            } else if (filter == 'Locked') {
                LockedOnly([...positionList])
            } else if(filter =='Yield_bearing'){
                BreaingOnly([...positionList])
            }
            else{
                setRecords([...positionList])
            }
        }
    }, [filter, positionList])

    const BoostedOnly = (records) => {
        try {
            const Record = records.filter((val) => {
                return val.boostPoints > 0 && !parseFloat(val.lockDuration) > 0
            })
            setRecords(Record)
        } catch (err) {
            console.log(err, 'BoostedOnly__err')
        }
    }

    const LockedOnly = (records) => {
        try {
            const Record = records.filter((val) => {
                return parseFloat(val.lockDuration) > 0 && !val.boostPoints > 0
            })
            setRecords(Record)
        } catch (err) {
            console.log(err, 'BoostedOnly__err')
        }
    }


    const BreaingOnly =(records)=>{
        try{
            const Record =  records.filter((val)=>{
                let poolData   =  nftpooldata.find((vals)=>(vals.poolAddress == val.poolAddress)) 
                return !val.boostPoints > 0 && !parseFloat(val.lockDuration) > 0 && parseFloat(poolData.allocPoint) > 0
            })
            setRecords(Record)
        }catch(err){
            console.log(err,'BoostedOnly__err')
        }
    }
    const userdetail = useSelector((state) => state.admin)
    return (
        <>
            <div className={`${cascading.innerpages} ${cascading.launchpadpage} ${cascading.pooltable} ${cascading.yieldadvtablewidth}`}>


                <div className={`${cascading.tabstyle} mt-3 table-responsive`} id='launchpadtable' >

                    <ReactDatatable
                        className={`${cascading.datatablestyle} ${cascading.datatablestylenohover}`}
                        config={config}
                        columns={columns}
                        records={records}
                    />
                </div>
            </div>

            {boostmodal && <Boostmodal show={boostmodal} onSuccess={() => { setModalsucess(true); }} onHide={() => { setBoostmodal(false) }} />}
            {unboostmodal && <Unboostmodal show={unboostmodal} onSuccess={() => { setModalsucess(true); }} onHide={() => { setUnboostmodal(false) }} />}

            {modalsucess && <Addsuccessmodal onHide={() => { setModalsucess(false) }} />}
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}

        </>

    )
}
