import React, { useEffect, useState } from 'react'
import cascading from '../assests/css/all.module.css';
import logo from '../assests/images/cardlogo.png'
import Walletmodal from '../modals/walletmodal';
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaWallet } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";

import { useSelector, useDispatch } from 'react-redux';
import pools_icon from '../assests/images/pools_icon.png';

import ReactDatatable from '@ashvin27/react-datatable';
import positionmodal from '../modals/positionmodal';


import { AiOutlineCalculator } from "react-icons/ai";
import { Link } from 'react-router-dom';
import Calculatormodal from '../modals/calculatormodal';
import { Accordion, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';
import { useNavigate } from 'react-router-dom';


//modals
import ComingSoonmodal from '../modals/comingSoonmodal';
import Positionmodal from '../modals/positionmodal';
import Addpositionmodal from '../modals/addpositionmodal';
import Addsuccessmodal from '../modals/addsuccessmodal';
import Harvestpositionmodal from '../modals/harvestpositionmodal';
import Withdrawpositionmodal from '../modals/withdrawpositionmodal';
import Lockpositionmodal from '../modals/lockpositionmodal';
import Boostpositionmodal from '../modals/boostpositionmodal';


//lib
import { isEmpty } from '../lib/isEmpty';
import fileObjectUrl from '../lib/img'
//constant
import { POSITION_RECORD } from '../constants';
import { roundToSignificant } from '../hooks/useCommon';


export default function Positionlist(props) {

    const dispatch = useDispatch()
    const { positionrecord ,positionList} = useSelector((state) => (state.position))
    const { nftpooldata } = useSelector((state) => (state.nftpool))
    console.log(positionList,'positionList')
    const {filter} = props
    const [positionmodal, setPositionmodal] = useState(false);


    const [addpositionmodal, setAddpositionmodal] = useState(false);
    const [withdrawpositionmodal, setWithdrawpositionmodal] = useState(false);
    const [harvestpositionmodal, setHarvestpositionmodal] = useState(false);
    const [lockpositionmodal, setLockpositionmodal] = useState(false);
    const [boostpositionmodal, setBoostpositionmodal] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);


    const [modalsucess, setModalsucess] = useState(false);
    const [title, settitle] = useState('')
    const [successLoader, setsuccessLoader] = useState(false)
    const [loaderText, setloaderText] = useState('')
    const [successText, setsuccessText] = useState('')
    const [errorText, seterrorText] = useState('')
    const [Trxn, setTrxn] = useState('')





    const config = {
        button: {
            excel: false,
            print: false,
            extra: false,
        },
        // show_filter:false,
        show_length_menu: false,
        show_pagination: false
    }

    const columns = [
        {
            key: "lpsymbol",
            text: "Token",
            className: "token",
            align: "left",
            sortable: true,
            cell: record => {
                let tokenA = record.token0
                let tokenB = record.token1
                return (
                    <>
                        <div className={`${cascading.imgsec} ${cascading.cursor_pointer_column}`}>
                            <div className={`${cascading.leftimgsec}`}>
                                <img src={isEmpty(tokenA) ? coinlogo : fileObjectUrl(tokenA.image)} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                <img src={isEmpty(tokenB) ? coinlogo : fileObjectUrl(tokenB.image)} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                            </div>
                            <div>
                                <p className={`${cascading.coinname} mb-0`}>{record.lpsymbol}</p>
                                <p className={`${cascading.coinnamegrey} mb-0`}>#ID-{record.tokenId}</p>
                            </div>
                        </div>
                    </>
                );
            }

        },
        {
            key: "Deposits",
            text: "Deposits",
            className: "tvl",
            align: "left",
            sortable: true,
            cell: record => {
                let tokenA = record.token0
                let tokenB = record.token1
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{roundToSignificant(record.amount, 6)} {record.lpsymbol}</p>
                        </div>
                    </>
                );
            }
        },
        {
            key: "aprrange",
            text: "APR Range",
            className: "aprrange",
            sortable: true,
            width: 80,
            cell: records => {
                let poolInfo = nftpooldata?.find((val)=>(val?.poolAddress.toLowerCase() == records?.poolAddress.toLowerCase()))
                return (
                    <div className='d-flex align-items-center'>
                        <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{poolInfo?.Apr}%</p>
                        <div>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-1'>Swap fees APR: {roundToSignificant(poolInfo?.SwapFeeApr, 4)}%</p>
                                        <p className='mb-1'>Farm APR: {roundToSignificant(poolInfo?.FramBaseApr, 4)}%</p>
                                        {/* <p className='mb-0'>YUM rewards: +3.14%</p> */}

                                    </Tooltip>
                                }
                            >
                                <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    // <img src={tick} alt='tick' className={`img-fluid`} width={17} height={17}/>
                )

            }
        },
        {
            key: "properties",
            text: "Properties",
            className: "properties",
            align: "left",
            sortable: true,
            cell: records => {
                console.log(records, "recordes")
                let islocked  = records.lockDuration > 0 ? true :false
                let isboosted =  records.boostPoints > 0 ? true :false
                let poolData   =  nftpooldata.find((val)=>(val.poolAddress == records.poolAddress))
                let isbeared =  parseFloat(poolData.allocPoint) > 0 ? true :false
                return (
                    <div className='d-flex align-items-center justify-content-start'>
                        <div className='pe-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Yield bearing</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={isbeared ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaCoins /></Button>
                            </OverlayTrigger>
                        </div>

                        <div className='px-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Lock/Unlock position</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={islocked ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaLock /></Button>
                            </OverlayTrigger>
                        </div>

                        <div className='px-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Boost/Unboost position</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={isboosted ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaRocket /></Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                )

            }
        },
        {
            key: "rewards",
            text: "Pending Rewards",
            className: "rewards",
            sortable: true,
            align: "left",
            cell: record => {
                return (
                    <div>
                        {/* <p className={`${cascading.coinname} mb-0`}>$0.05</p> */}
                        {/* <p className={`${cascading.coinnamegrey} mb-0`}><FaAngleLeft />0.001 DSP</p> */}
                        <p className={`${cascading.coinname} mb-0`}>{roundToSignificant(record.dspRewards, 6)} DSP</p>
                    </div>
                )

            }
        },
        {
            key: "action",
            text: "",
            className: "action",
            sortable: true,
            align: "left",
            cell: (record) => {

                return (
                    <div className='d-flex align-items-center justify-content-between'>
                          <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Add Position</p>

                                    </Tooltip>
                                }
                            >
                        <button className='btn_trans_icon_table' onClick={() => { ShowAddModal(record) }}>
                           
                            <FaDownload />
                        </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Withdraw Position</p>

                                    </Tooltip>
                                }
                            >

                        <button className='btn_trans_icon_table' disabled={!record.isWithdraw} onClick={() => { ShowWithdrawModal(record) }}>
                            <FaUpload />
                        </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Harvest Position</p>

                                    </Tooltip>
                                }
                            >

                        <button className='btn_trans_icon_table' onClick={() => { ShowHarvestModal(record) }}>
                            <FaWallet />
                        </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Lock Position</p>

                                    </Tooltip>
                                }
                            >

                        <button className='btn_trans_icon_table' onClick={() => { ShowLockmodal(record) }}>
                            <FaLock />
                        </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Boost/Unboost Position</p>

                                    </Tooltip>
                                }
                            >

                        <button className='btn_trans_icon_table' onClick={() => { ShowBoostModal(record) }}>
                            <FaRocket />
                        </button>
                        </OverlayTrigger>
                    </div>
                )

            }
        },


    ];

    const setRecordData = (data) => {
        return {
            type: POSITION_RECORD,
            payload: data
        }
    }

    const ShowPositionModal = (record) => {
        try {
            setPositionmodal(true)
            dispatch(setRecordData(record))
        } catch (err) {
            console.log(err, 'ShowAddModal_err')
        }
    }
    const HidePositionModal = () => {
        try {
            setPositionmodal(false)
            dispatch(setRecordData({}))
        } catch (err) {
            console.log(err, 'ShowAddModal_err')
        }
    }

    const ShowAddModal = (record) => {
        try {
            dispatch(setRecordData(record))
            setPositionmodal(false);
            setAddpositionmodal(true)
        } catch (err) {
            console.log(err, 'ShowAddModal_err')
        }
    }
    const ShowWithdrawModal = (record) => {
        try {
            dispatch(setRecordData(record))
            setPositionmodal(false);
            setWithdrawpositionmodal(true)
        } catch (err) {
            console.log(err, 'ShowAddModal_err')
        }
    }

    const ShowHarvestModal = (record) => {
        try {
            dispatch(setRecordData(record))
            setPositionmodal(false);
            setHarvestpositionmodal(true);
        } catch (err) {
            console.log(err, 'ShowAddModal_err')
        }
    }

    const ShowBoostModal = (record) => {
        try {
            dispatch(setRecordData(record))
            setPositionmodal(false);
            setBoostpositionmodal(true);
        } catch (err) {
            console.log(err, 'ShowAddModal_err')
        }
    }

    const ShowLockmodal = (record) => {
        try {
            setPositionmodal(false);
            dispatch(setRecordData(record))
            setLockpositionmodal(true);
        } catch (err) {
            console.log(err, 'ShowAddModal_err')
        }
    }

    const showsuccesModal = (text, title) => {
        try {
            settitle(title)
            setloaderText(text)
            setsuccessLoader(false)
            setModalsucess(true);
        } catch (err) {
            console.log(err, 'showsuccesModal___err')
        }
    }

    const SetsuccessLoader = (text, hash) => {
        setTrxn(hash)
        setsuccessText(text);
        setsuccessLoader(true)
    }

    const setError = (text) => {
        seterrorText(text);
        setsuccessLoader(undefined)
    }

    const userdetail = useSelector((state) => state.admin)
    const [records,setRecords] = useState([])
    useEffect(()=>{
        if(!isEmpty(positionList)){
            if(filter == 'Boosted'){
                BoostedOnly([...positionList])
            }else if(filter == 'Locked'){
                LockedOnly([...positionList])
            }else if(filter =='Yield_bearing'){
                BreaingOnly([...positionList])
            }
            else{
                setRecords([...positionList])
            }
        }else{
            setRecords([])
        }
    },[filter,positionList])

    const BoostedOnly =(records)=>{
        try{
            const Record =  records.filter((val)=>{
                let poolData   =  nftpooldata.find((vals)=>(vals.poolAddress == val.poolAddress)) 
                return val.boostPoints > 0 && !val.lockDuration > 0 && !(parseFloat(poolData.allocPoint) > 0)
            })
            setRecords(Record)
        }catch(err){
            console.log(err,'BoostedOnly__err')
        }
    }

    const LockedOnly =(records)=>{
        try{
            const Record =  records.filter((val)=>{
                let poolData   =  nftpooldata.find((vals)=>(vals.poolAddress == val.poolAddress)) 
                return val.lockDuration > 0 && !val.boostPoints > 0 && !(parseFloat(poolData?.allocPoint) > 0)
            })
            setRecords(Record)
        }catch(err){
            console.log(err,'BoostedOnly__err')
        }
    }

    const BreaingOnly =(records)=>{
        try{
            const Record =  records.filter((val)=>{
                let poolData   =  nftpooldata.find((vals)=>(vals.poolAddress == val.poolAddress)) 
                return !val.boostPoints > 0 && !parseFloat(val.lockDuration) > 0 && parseFloat(poolData?.allocPoint) > 0
            })
            setRecords(Record)
        }catch(err){
            console.log(err,'BoostedOnly__err')
        }
    }
    return (
        <>
            <div className={`${cascading.innerpages} ${cascading.launchpadpage} ${cascading.pooltable} ${cascading.pooltablewidth}`}>


                <div className={`${cascading.tabstyle} mt-3 table-responsive`} id='launchpadtable' >

                    <ReactDatatable
                        className={`${cascading.datatablestyle} ${cascading.datatablestylenohover}`}
                        config={config}
                        columns={columns}
                        records={records}
                        onRowClicked={(e, record) => {
                            console.log(record, 'ReactDatatable');
                            ShowPositionModal(record)
                        }}
                    />
                </div>
            </div>





            {positionmodal && <Positionmodal show={positionmodal}
                addmodal={() => setAddpositionmodal(true)}
                harvestmodal={() => setHarvestpositionmodal(true)}
                withdrawmodal={() => setWithdrawpositionmodal(true)}
                lockmodal={() => setLockpositionmodal(true)}
                boostmodal={() => setBoostpositionmodal(true)}
                onHide={() => { setPositionmodal(false) }}
            />
            }

            {addpositionmodal && <Addpositionmodal
                show={addpositionmodal}
                onSuccess={(text, title) => { 
                    showsuccesModal(text, title); 
                    setAddpositionmodal(false);
                    setPositionmodal(false);
                }}
                SetsuccessLoader={(text, hash) => { 
                    SetsuccessLoader(text, hash) 
                }}
                setError={(text) => { 
                    setError(text) 
                }}
                onhideposition={() => { 
                    HidePositionModal(); 
                }}
                onHide={() => { 
                    setAddpositionmodal(false) 
                }}
            />
            }

            {withdrawpositionmodal && <Withdrawpositionmodal
                show={withdrawpositionmodal}
                onSuccess={(text, title) => { 
                    showsuccesModal(text, title); 
                    setWithdrawpositionmodal(false) 
                    setPositionmodal(false);
                }}
                SetsuccessLoader={(text, hash) => { SetsuccessLoader(text, hash) }}
                setError={(text) => { setError(text) }}
                positionmodal={() => setPositionmodal(true)}
                onhideposition={() => { HidePositionModal(); }}
                onHide={() => { setWithdrawpositionmodal(false) }}
            />}

            {harvestpositionmodal && <Harvestpositionmodal
                show={harvestpositionmodal}
                onSuccess={(text, title) => { 
                    showsuccesModal(text, title); 
                    setHarvestpositionmodal(false) 
                    setPositionmodal(false);
                }}
                SetsuccessLoader={(text, hash) => { SetsuccessLoader(text, hash) }}
                setError={(text) => { setError(text) }}
                positionmodal={() => setPositionmodal(true)}
                onhideposition={() => { HidePositionModal(); }}
                onHide={() => { setHarvestpositionmodal(false) }}
            />}

            {lockpositionmodal && <Lockpositionmodal
                show={lockpositionmodal}
                onSuccess={(text, title) => { 
                    showsuccesModal(text, title); 
                    setLockpositionmodal(false) 
                    setPositionmodal(false);
                }}
                SetsuccessLoader={(text, hash) => { SetsuccessLoader(text, hash) }}
                setError={(text) => { setError(text) }}
                positionmodal={() => setPositionmodal(true)}
                onhideposition={() => { HidePositionModal(); }}
                onHide={() => { setLockpositionmodal(false) }}
            />}

            {boostpositionmodal && <Boostpositionmodal
                show={boostpositionmodal}
                onSuccess={(text, title) => { 
                    showsuccesModal(text, title); 
                    setBoostpositionmodal(false) 
                    setPositionmodal(false);
                }}
                SetsuccessLoader={(text, hash) => { SetsuccessLoader(text, hash) }}
                setError={(text) => { setError(text) }}
                positionmodal={() => setPositionmodal(true)}
                onhideposition={() => { HidePositionModal(); }}
                onHide={() => { setBoostpositionmodal(false) }}
            />}

            {modalsucess && <Addsuccessmodal
                show={modalsucess}
                title={title}
                loaderText={loaderText}
                successText={successText}
                isLoader={successLoader}
                lpSymbol={positionrecord.lpsymbol}
                hash={Trxn}
                errorText={errorText}
                onHide={() => { setModalsucess(false); }}
            />}
            {/* {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />} */}

        </>

    )
}
