import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BiRightArrowAlt } from "react-icons/bi";
import { FaAngleLeft } from "react-icons/fa";

//lib
import { Duration } from '../lib/DateTimeHelper';

//hookss
import { getWalletAddress } from '../lib/localStorage';

import Countdown from 'react-countdown';
import { roundToSignificant } from '../hooks/useCommon';
import { FinalRedeem } from '../hooks/useXtoken';

import toast from 'react-hot-toast';

export default function Redeemcard(props) {

    const [isClaim, setisClaim] = useState([])
    const [loader, setloader] = useState(false)
    const [btnenable, setbtnenable] = useState(false)
    const { val, index } = props

    useEffect(() => {
        if (val.isClaim) {
            setisClaim(true)
        } else {
            setisClaim(false)
        }
    }, [val])

    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }
    const Completionist = () => <span className={`${cascading.themetime}`}>00D 00h 00m 00s</span>;


    const CalimRedeem = async () => {
        try {
            setloader(true)
            setbtnenable(true)
            let result = await FinalRedeem(getWalletAddress(), index)
            if (result) {
                setloader(false)
                setbtnenable(false)
                console.log(result, 'CalimRedeem')
                toast.success('Redeem calim successfuly')
            } else {
                setloader(false)
                setbtnenable(false)
                console.log(result, 'CalimRedeem')
                toast.error('Try again')
            }
        } catch (err) {
            setloader(false)
            setbtnenable(false)
            console.log(err, 'CalimRedeem__err')
            toast.error('Try again')
        }
    }
    return (
        <>
            <div className='row align-items-center pb-4'>
                <div className='col-3 col-sm-3 col-md-3 text-center text-sm-start pe-0'>
                    <p className={`mb-0 ${cascading.textwhiteyum}`}>{roundToSignificant(val.DSPAmount, 6)} DSP</p>
                </div>
                <div className='col-1 col-sm-1 col-md-1 text-center text-sm-start ps-1 pe-0'>
                    <BiRightArrowAlt fill='#fff' />
                </div>
                <div className='col-3 col-sm-4 col-md-4 text-sm-start text-center ps-1 pe-0'>
                    <p className={`mb-0 ${cascading.textwhiteyum}`}>
                        {<Countdown
                            date={new Date(val.endTime * 1000)}
                            renderer={({ days, hours, minutes, seconds, completed }) => {
                                try {
                                    console.log(days, hours, minutes, seconds, completed, 'renderer');
                                    if (completed) {
                                        // Render a completed state
                                        setisClaim(true)
                                        return Completionist();

                                    } else {
                                        // Render a countdown
                                        return <span className={`${cascading.themetime}`}>{formatTime(days)}D {formatTime(hours)}h {formatTime(minutes)}m {formatTime(seconds)}s</span>;
                                    }
                                } catch (err) {
                                    console.log(err, 'Countdown___err')
                                }
                            }}
                        />}
                    </p>
                </div>
                <div className='col-1 col-md-1 col-sm-1 text-center text-sm-start ps-1 pe-0'>
                    <BiRightArrowAlt fill='#fff' />
                </div>
                <div className='col-3 col-md-3 col-sm-3 text-center text-sm-start ps-1 pe-0'>
                    {/* <FaAngleLeft fill='#FE80C9' /> */}
                    <span className={`mb-0 ${cascading.textthemeyum}`}>{roundToSignificant(val.YUMAmount, 6)} YUM</span>
                </div>
                {
                    isClaim ? <div className='text-center text-sm-center mt-3'>
                        <button
                            className={`btn me-0 me-sm-2 ${cascading.stakebtnicon}`}
                            disabled={btnenable}
                            onClick={() => {
                                CalimRedeem()
                            }}
                        >
                            {loader ? 'Claiming..' : 'Claim'}
                        </button>
                    </div> : ''
                }

            </div>
        </>
    )
}
