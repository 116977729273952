
import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/settingstyle.module.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaRegQuestionCircle } from "react-icons/fa";
import Switch from "react-switch";
import Expertsmodal from './expertsmodal';
import metaicon from '../assests/images/metamask.png';
import walleticon from '../assests/images/walletconnect.png';
import { useDispatch, useSelector } from 'react-redux';
import { parseUnits } from 'ethers/lib/utils'

import { IoMdClose } from "react-icons/io";

//lib
import { consolelog } from '../lib/consolelog';
import { getMultiHops, getSlippage, setDeadLine_local, setExpertMode, setMultiHops, setSlippage } from '../lib/localStorage';
import { toFixedNumber } from '../lib/FixedNumber';

//validation 
import { SlippageValidation } from '../validation/SettingsValidation';

//constant 
import { DEADLINE, EXPERT_MODE, MULTI_HOPS, SLIPPAGE, GAS_PRICE } from '../constants';
import { MAX_HOPS } from '../config/env';



export default function Settingsmodal(props) {


  const dispatch = useDispatch()
  // state 
  const [showexpert, setShowexpert] = useState(false);
  const [settingsmodal, setSettingsmodal] = useState(true)

  const [checked, setChecked] = useState(false);
  const [multihopscheck, setMultihopscheck] = useState(false);
  const [userSlippageTolerance, setUserSlippageTolerance] = useState('')
  const [slippageInput, setSlippageInput] = useState('')
  const [slippageError, setslippageError] = useState('')
  const [checked1, setChecked1] = useState(false);
  const [multiHops, setmultiHops] = useState(false)
  const [DeadLine, setDeadLine] = useState(0)

  console.log(JSON.parse(multiHops),'multiHops')

  //selector
  const { slippage, deadline, expertmode } = useSelector((state) => state.settings)
  const { gasPrice } = useSelector((state) => state.gasSettings)
  console.log("gasPrice_redux",gasPrice)

  //useEffect
  useEffect(() => {
    if (getSlippage()) {
      setUserSlippageTolerance(parseFloat(getSlippage()) * 100)
      setSlippageInput(getSlippage())
    }
    setmultiHops(JSON.parse(getMultiHops()))
  }, [])



  useEffect(() => {
    let error = SlippageValidation(slippageInput, userSlippageTolerance)
    setslippageError(error)
  }, [slippageInput])


  //Onchange function

  const SlippageChange = (e) => {
    try {
      const { value, id } = e.target
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      consolelog('handleChange', { val: value, id: id, 'con': !numbers.test(value) && value !== "" }, true)
      if (!numbers.test(value) && value !== "") {
        return false
      }
      if (id == 'slippageInput') {
        setSlippageInput(value)

        let userSlippageTolerance = parseFloat(value) * 100
        if (!isNaN(userSlippageTolerance) && userSlippageTolerance != 0) {
          setUserSlippageTolerance(userSlippageTolerance)
          setSlippage(userSlippageTolerance / 100)
          dispatch({
            type: SLIPPAGE,
            payload: value
          })
        }
      }

    } catch (err) {
      consolelog('SlippageChange__err', err, true)
    }
  }

  const OnpercentageChange = (value) => {
    try {
      setSlippageInput(value)
      setUserSlippageTolerance(value * 100)
      setSlippage(value)
      dispatch({
        type: SLIPPAGE,
        payload: value
      })
    } catch (err) {
      consolelog('OnpercentageChange__err', err, true)
    }
  }

  const OnmultiHopChange = (e) => {
    try {
      const { value, name } = e.target
      if (value > MAX_HOPS) {
        return false
      }
      setmultiHops(value)
      setMultiHops(value)
      dispatch({
        type: MULTI_HOPS,
        payload: value
      })
    } catch (err) {
      consolelog('multiHop__err', err, true)
    }
  }

  const OnDeadLineChange = (e) => {
    try {
      const { value, name } = e.target
      setDeadLine(value)
      setDeadLine_local(value)
      dispatch({
        type: DEADLINE,
        payload: value
      })
    } catch (err) {
      consolelog('multiHop__err', err, true)
    }
  }
  const handleChange = nextChecked => {
    console.log(nextChecked, 'nextChecked')
    setChecked(nextChecked);
    if (nextChecked) {
      setShowexpert(true);
      setSettingsmodal(false);
    } else {
      console.log(nextChecked, 'handleChange')
      setExpertMode(nextChecked)
      dispatch({
        type: EXPERT_MODE,
        payload: nextChecked
      })
    }

  };

  const handleChange1 = nextChecked1 => {
    setChecked1(nextChecked1);
  };


  const GAS_PRICES = {
    standard: '5',
    fast: '6',
    instant: '7'
  }


  const GAS_PRICE_GWEI = (type) => {

    let gasPrice = parseUnits(GAS_PRICES[type], 'gwei').toString()
    console.log("gasPrice",gasPrice,type)
    let data = {
      gasPrice:gasPrice,
      type:type
    }
    localStorage.setItem('gasPrice',JSON.stringify(data))   

    dispatch({
      type: GAS_PRICE,
      payload:data
    })   
    
    

    // let fast = parseUnits(GAS_PRICE.fast, 'gwei').toString()
    // console.log("fast",fast)
    // let instant = parseUnits(GAS_PRICE.instant, 'gwei').toString()
    // console.log("instant",instant)

  }


  // useEffect(()=>{
  //   GAS_PRICE_GWEI()
  // },[])

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={settingsmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Setting</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
            <p className={`${cascading.innerhead}`}>Global</p>

            <p className={`${cascading.innerheadwhite}`}>Default Transaction Speed (GWEI)
              <OverlayTrigger
                className={`${cascading.tooltip_style}`}
                key="right"
                placement="right"
                overlay={
                  <Tooltip id={`tooltip-right`}>
                    Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees
                  </Tooltip>
                }
              >
                <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
              </OverlayTrigger></p>

            <ul className={`${cascading.button_ul}`}>
              <li>
                <button className={ gasPrice.type =='standard' ? `btn me-2 ${cascading.buttonstyle_ul} ${cascading.active}` : `btn me-2 ${cascading.buttonstyle_ul}`} onClick={()=>{GAS_PRICE_GWEI('standard')}}>Standard (5)</button>
              </li>
              <li>
                <button className={ gasPrice.type =='fast' ? `btn me-2 ${cascading.buttonstyle_ul} ${cascading.active}` : `btn me-2 ${cascading.buttonstyle_ul}`} onClick={()=>{GAS_PRICE_GWEI('fast')}}>Fast (6)</button>
              </li>
              <li>
                <button className={gasPrice.type =='instant' ? `btn ${cascading.buttonstyle_ul}` : `btn me-2 ${cascading.buttonstyle_ul}`} onClick={()=>{GAS_PRICE_GWEI('instant')}}>Instant (7)</button>
              </li>
            </ul>


            <p className={`mt-4 ${cascading.innerhead}`}>Swaps & Liquidity</p>

            <p className={`${cascading.innerheadwhite}`}>Slippage Tolerance
              <OverlayTrigger
                className={`${cascading.tooltip_style}`}
                key="right"
                placement="right"
                overlay={
                  <Tooltip id={`tooltip-right`}>
                    Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.
                  </Tooltip>
                }
              >
                <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
              </OverlayTrigger></p>

            <ul className={`${cascading.button_ul}`}>
              <li>
                <button
                  className={`btn me-2 ${cascading.buttonstyle_ul}`}
                  onClick={() => {
                    OnpercentageChange(0.1)
                    setslippageError('Your transaction may fail')
                  }}
                >
                  0.1%
                </button>
              </li>
              <li>
                <button
                  className={`btn me-2 ${cascading.buttonstyle_ul}`}
                  onClick={() => {
                    OnpercentageChange(0.5)
                    setslippageError('')
                  }}
                >
                  0.5%
                </button>
              </li>
              <li>
                <button
                  className={`btn me-2 ${cascading.buttonstyle_ul}`}
                  onClick={() => {
                    OnpercentageChange(1.0)
                    setslippageError('')
                  }}
                >
                  1.0%
                </button>
              </li>
              <li>
                <div className='d-flex align-items-center'>
                  <input
                    type='text'
                    autoComplete={"off"}
                    className={`form-control ${cascading.sliipage_input}`}
                    id='slippageInput'
                    value={slippageInput}
                    onChange={SlippageChange}
                  />
                  <span className={`${cascading.percentage_text}`}>%</span>
                </div>
              </li>
            </ul>
            <p className={`${cascading.orangetext}`}>{slippageError && slippageError}</p>
            <div className='d-flex align-items-center justify-content-between mt-4'>
              <p className={`mb-0 ${cascading.innerheadwhite}`}>Tx deadline (mins)
                <OverlayTrigger
                  className={`${cascading.tooltip_style}`}
                  key="right"
                  placement="right"
                  overlay={
                    <Tooltip id={`tooltip-right`}>
                      Your transaction will revert if it is left confirming for longer than this time.
                    </Tooltip>
                  }
                >
                  <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                </OverlayTrigger></p>
              <div className='d-flex align-items-center'>
                <input
                  type='text'
                  autoComplete={"off"}
                  value={deadline}
                  className={`form-control ${cascading.sliipage_input} ${cascading.sliipage_input_sm}`}
                  onChange={(e) => {
                    OnDeadLineChange(e)
                  }}
                />
              </div>
            </div>


            <div className='d-flex align-items-center justify-content-between mt-4'>
              <p className={`mb-0 ${cascading.innerheadwhite}`}>Multi Hops
                <OverlayTrigger
                  className={`${cascading.tooltip_style}`}
                  key="right"
                  placement="right"
                  overlay={
                    <Tooltip id={`tooltip-right`}>
                      Restricts swaps to direct pairs only.
                    </Tooltip>
                  }
                >
                  <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                </OverlayTrigger></p>
              <div className={`col-lg-2 ${cascading.togglesec}`}>

                <Switch
                  onChange={(e) => {
                    console.log(e, "multiHops")
                    setmultiHops(e)
                    setMultiHops(e)
                    dispatch({
                      type: MULTI_HOPS,
                      payload: e
                    })
                  }}
                  checked={multiHops}
                  className="react-switch"
                  onHandleColor="#232833"
                  offHandleColor='#232833'
                  onColor="#FE80C9"
                  // handleDiameter={10}
                  offColor='#6a6c6e'
                  //  handleDiameter={10}
                  height={30}
                  width={56}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />


              </div>
              {/* <div className='d-flex align-items-center'>
                <input
                  type='text'
                  value={multiHops}
                  className={`form-control ${cascading.sliipage_input} ${cascading.sliipage_input_sm}`}
                  onChange={(e) => {
                    OnmultiHopChange(e)
                  }}
                />
              </div> */}
            </div>

            <div className='d-flex align-items-center justify-content-between mt-4 pb-4'>
              <p className={`mb-0 ${cascading.innerheadwhite}`}>Expert Mode
                <OverlayTrigger
                  className={`${cascading.tooltip_style}`}
                  key="right"
                  placement="right"
                  overlay={
                    <Tooltip id={`tooltip-right`}>
                      Bypasses confirmation modals and allows high slippage trades. Use at your own risk.
                    </Tooltip>
                  }
                >
                  <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                </OverlayTrigger></p>
              <div className={`col-lg-2 ${cascading.togglesec}`}>

                <Switch
                  onChange={handleChange}
                  checked={expertmode}
                  className="react-switch"
                  onHandleColor="#232833"
                  offHandleColor='#232833'
                  onColor="#FE80C9"
                  // handleDiameter={10}
                  offColor='#6a6c6e'

                  //  handleDiameter={10}
                  height={30}
                  width={56}
                  uncheckedIcon={false}
                  checkedIcon={false}

                />


              </div>
            </div>

            {/* <div className='d-flex align-items-center justify-content-between mt-3 pb-4'>
              <p className={`mb-0 ${cascading.innerheadwhite}`}>Disable Multihops

                <OverlayTrigger
                  className={`${cascading.tooltip_style}`}
                  key="right"
                  placement="right"
                  overlay={
                    <Tooltip id={`tooltip-right`}>
                      Restricts swaps to direct pairs only.
                    </Tooltip>
                  }
                >
                  <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                </OverlayTrigger></p>
              <div className={`col-lg-2 ${cascading.togglesec}`}>

                <Switch
                  onChange={handleChange1}
                  checked={checked1}
                  className="react-switch"
                  onHandleColor="#1C2029"
                  onColor="#FE80C9"
                  offHandleColor="#232833"
                  offColor="#1c2029"

                  //  handleDiameter={10}
                  height={30}
                  width={56}
                  uncheckedIcon={false}
                  checkedIcon={false}

                />


              </div>
            </div> */}

            <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={props.onHide}>Save</button>
            <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>


          </Modal.Body>

        </Modal>
      </div>
      {showexpert && <Expertsmodal show={showexpert} onHide={() => { setShowexpert(false); setSettingsmodal(true); setChecked(false); }} />}
    </>
  )
}

