import React, { useState, useEffect } from 'react';
import cascading from '../../assests/css/farms.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../../common/footer'
import Header from '../../common/header';
import { useNavigate } from 'react-router-dom';
import { FaThList } from "react-icons/fa";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import Switch from "react-switch";
import { Dropdown } from 'react-bootstrap';
import Farmcard from '../../components/farmcard';
import bnblogo from '../../assests/images/bnb.png';
import coinlogo from '../../assests/images/coinicon.png';
import Farmlist from '../../components/farmlist';
import BigNumber from 'bignumber.js'

//hooks
import { getFarmsInfo } from '../../hooks/useFarms';
import { getTokens } from '../../hooks/useTokens'
import { UserDeposits } from '../../hooks/useStake'

//lib
import { isEmpty } from "../../lib/isEmpty";
import { toFixedNumber } from '../../lib/FixedNumber';
import { useSelector } from 'react-redux';

let crtsearch = '';
let StakedOnly = false

export default function Farms() {
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false);
    const [listtype, setListtype] = useState(false);
    const [status, setStatus] = useState(true);
    const [stakedOnly, setStakedOnly] = useState(false)
    const [sortBy, setSortBy] = useState('Hot')


    const [farmdetails, setFarmdetails] = useState([{
        firstimg: bnblogo,
        secimg: coinlogo,
        fromname: "DSP",
        toname: "BNB",
        apr: "39,401,732,475.65%",
        multi: "1",
        liquidity: "0",
        earned: "0"

    },
    {
        firstimg: bnblogo,
        secimg: coinlogo,
        fromname: "DSP",
        toname: "BNB",
        apr: "39,401,732,475.65%",
        multi: "1",
        liquidity: "0",
        earned: "0"

    },
    {
        firstimg: bnblogo,
        secimg: coinlogo,
        fromname: "DSP",
        toname: "BNB",
        apr: "39,401,732,475.65%",
        multi: "1",
        liquidity: "0",
        earned: "0"

    }])

    const [Farms, setFarms] = useState([]);
    const [activeFarms, setActiveFarms] = useState([])
    const [inactiveFarms, setinActiveFarms] = useState([])
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [stakedAmount, setStakedAmount] = useState();
    const [loading, setLoading] = useState(true)

    const { FarmData } = useSelector((state) => (state.farm))

    useEffect(() => {
        console.log("testnetTokens")

        let results = []

        if (!isEmpty(searchTerm)) {
            let regx = new RegExp(searchTerm, 'i')

            results = activeFarms?.filter((val) => {
                console.log("activeFarms_search", val?.lpSymbol)
                return (val?.lpSymbol?.match(regx))
            })

        }
        else {
            results = activeFarms
        }
        setSearchResults(results);
        console.log(activeFarms, "search", results)
    }, [searchTerm]);

    const onChangefn = (e) => {
        setSearchTerm(e.target.value);
        crtsearch = e.target.value;
    };


    useEffect(() => {
        // farmsInfo()

        console.log("getFarmsInfo", FarmData, !isEmpty(FarmData), FarmData == false)
        if (!isEmpty(FarmData) && FarmData != false) {
            setLoading(true)
            console.log("getFarmsInfo1", FarmData, !isEmpty(FarmData), FarmData == false)

            console.log("getFarmsInfo2", FarmData)
            setFarms(FarmData);
            let active = FarmData?.filter((farm) => {
                console.log(!!farm.isTokenOnly === !!false && farm.multiplier !== '0X', 'filter', !!true, !!farm.isTokenOnly)
                return !!farm.isTokenOnly === !!false && farm.multiplier !== '0X'
            })

            console.log('dattttaaa', active, crtsearch, searchTerm)
            let searchactive = active
            if (!isEmpty(crtsearch)) {
                let regx = new RegExp(crtsearch, 'i')
                searchactive = searchactive?.filter((val) => {
                    console.log("activeFarms_search", val?.lpSymbol)
                    return (val?.lpSymbol?.match(regx))
                })

            }
            console.log("searchactive", StakedOnly)
            if (StakedOnly) {
                searchactive = searchactive?.filter((val) => {
                    console.log("searchactive", val, val.StakedAmount)
                    return val.StakedAmount > 0
                })
            }
            let SortBy = sortBy
            if (SortBy == 'Hot') {
                searchactive = HotOnclick(searchactive)

            }
            if (SortBy == 'APR') {
                searchactive = APROnclick(searchactive)
            }
            if (SortBy == 'Multiplier') {
                searchactive = MultiplierOnclick(searchactive)
            }
            if (SortBy == 'Earned') {
                searchactive = EarnedOnclick(searchactive)
            }
            if (SortBy == 'Liquidity') {
                searchactive = Liquidity(searchactive)
            }


            const inactive = FarmData?.filter((farm) => !!farm.isTokenOnly === !!false && farm.multiplier === '0X')
            setActiveFarms(active);
            setSearchResults(searchactive);
            setinActiveFarms(inactive);
            setLoading(false)

            // let farms =[]
            console.log(active, 'farms__farms')
        }
        else if (FarmData == false) {
            setLoading(false)
        }
    }, [FarmData])

    // const farmsInfo = async () => {
    //     setLoading(true)
    //     console.log("form")
    //     var data
    //     if (localStorage.getItem('FarmInterval')) {
    //         let oldInterval = localStorage.getItem('FarmInterval')
    //         clearInterval(oldInterval);
    //     }
    //     const interval = setInterval(async () => {
    //         console.log("interval")

    //         data = await getFarmsInfo()
    //         console.log("getFarmsInfo", data)
    //         setFarms(data);
    //         let active = data?.filter((farm) => {
    //             console.log(!!farm.isTokenOnly === !!false && farm.multiplier !== '0X', 'filter', !!true, !!farm.isTokenOnly)
    //             return !!farm.isTokenOnly === !!false && farm.multiplier !== '0X'
    //         })

    //         console.log('dattttaaa', active, crtsearch, searchTerm)
    //         let searchactive = active
    //         if (!isEmpty(crtsearch)) {
    //             let regx = new RegExp(crtsearch, 'i')
    //             searchactive = searchactive?.filter((val) => {
    //                 console.log("activeFarms_search", val?.lpSymbol)
    //                 return (val?.lpSymbol?.match(regx))
    //             })

    //         }
    //         console.log("searchactive", StakedOnly)
    //         if (StakedOnly) {
    //             searchactive = searchactive?.filter((val) => {
    //                 console.log("searchactive", val, val.StakedAmount)
    //                 return val.StakedAmount > 0
    //             })
    //         }


    //         const inactive = data.filter((farm) => !!farm.isTokenOnly === !!false && farm.multiplier === '0X')
    //         setActiveFarms(active);
    //         setSearchResults(searchactive);
    //         setinActiveFarms(inactive);
    //         setLoading(false)

    //         // let farms =[]
    //         console.log(active, 'farms__farms')
    //     }, 3000);
    //     localStorage.setItem('FarmInterval', interval)




    //     // if ((localStorage.getItem("Farms"))) {
    //     //     setFarms(JSON.parse(localStorage.getItem("Farms")));
    //     //     let Farms = JSON.parse(localStorage.getItem("Farms"))
    //     //     const active = Farms.filter((farm) => !!farm.isTokenOnly === !!false && farm.multiplier !== '0X')
    //     //     console.log("active", active)
    //     //     const inactive = Farms.filter((farm) => !!farm.isTokenOnly === !!false && farm.multiplier === '0X')
    //     //     console.log("inactive", inactive)
    //     //     setActiveFarms(active);
    //     //     setSearchResults(active)
    //     //     setinActiveFarms(inactive);
    //     //     return
    //     // }




    //     console.log("interval", interval)


    //     // for(let i = 0;i<data.length;i++){
    //     //     let e= data[i]

    //     //     // return{
    //     //     //     ...farm,
    //     //     //     StakedAmount:
    //     //     // }
    //     //     if(i==data.length-1){
    //     //         console.log('StakedAmount',farms)

    //     //         setActiveFarms(farms)
    //     //         setSearchResults(farms)
    //     //     }
    //     // }


    // }


    // const handleChange = nextChecked => {
    //     setChecked(nextChecked);
    // };
    console.log('activea', activeFarms)
    console.log('inactiveFarms', inactiveFarms)


    const stakedPools = (status) => {
        console.log('stakedPools')
        setChecked(status);
        setStakedOnly(status)
        StakedOnly = status
        if (status) {
            let FilterFarm = activeFarms?.filter((val) => {
                console.log("val", val, val.StakedAmount)
                return val.StakedAmount > 0
            })
            console.log(FilterFarm, 'FilterFarm')
            setSearchResults(FilterFarm)
        } else {
            setSearchResults(activeFarms)
        }

    }

    const HotOnclick = (activeFarms) => {
        console.log("HotOnclick", activeFarms)
        if (stakedOnly) {
            activeFarms = activeFarms?.filter((val) => {
                console.log("searchactive", val, val.StakedAmount)
                return val.StakedAmount > 0
            })
        }

        setSearchResults(activeFarms)
        return activeFarms
    }

    const APROnclick = (activeFarms) => {
        console.log("APROnclick")
        let Active = [...activeFarms]
        if (stakedOnly) {
            Active = Active?.filter((val) => {
                console.log("searchactive", val, val.StakedAmount)
                return val.StakedAmount > 0
            })
        }
        let apr = Active?.sort((a, b) => {
            console.log("apr", a, b)
            return a.apy - b.apy
        })
        console.log("apr", apr)
        setSearchResults(apr)
        return apr

    }

    const MultiplierOnclick = (activeFarms) => {
        console.log("multiplierOnclick")
        let Active = [...activeFarms]
        if (stakedOnly) {
            Active = Active?.filter((val) => {
                console.log("searchactive", val, val.StakedAmount)
                return val.StakedAmount > 0
            })
        }
        let multiplier = Active?.sort((a, b) => {
            console.log("multiplier", a, b)
            return b.multiplier - a.multiplier
        })
        console.log("multiplier", multiplier)
        setSearchResults(multiplier)
        return multiplier

    }




    const EarnedOnclick = (activeFarms) => {
        console.log("earnings")
        let Active = [...activeFarms]
        if (stakedOnly) {
            Active = Active?.filter((val) => {
                console.log("searchactive", val, val.StakedAmount)
                return val.StakedAmount > 0
            })
        }
        let earnings = Active?.sort((a, b) => {
            console.log("earnings", a, b)
            return b.earnings - a.earnings
        })
        console.log("earnings", earnings)
        setSearchResults(earnings)
        return earnings

    }


    const Liquidity = (activeFarms) => {
        let Active = [...activeFarms]
        if (stakedOnly) {
            Active = Active?.filter((val) => {
                console.log("searchactive", val, val.StakedAmount)
                return val.StakedAmount > 0
            })
        }
        let Liquidity = Active?.sort((a, b) => {
            const totalLiquidity = new BigNumber(a?.lpTotalInQuoteToken).times(a?.quoteTokenPriceBusd)
            const totalLiquidity2 = new BigNumber(b?.lpTotalInQuoteToken).times(b?.quoteTokenPriceBusd)
            return (totalLiquidity2).toString() - (totalLiquidity).toString()
        })
        console.log("Liquidity", Liquidity)
        setSearchResults(Liquidity)
        return Liquidity

    }

    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.earnpage}`}>
                <div className={`container`}>




                    <div className={`row ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>Farms</p>
                        <p className={`${cascading.pagedesc}`}>Stake LP tokens to earn.</p>
                    </div>

                    <div className={`row ${cascading.filterrow}`}>
                        <div className={`col-lg-6 mb-3 mb-lg-0`}>
                            <div className={`${cascading.leftfiltersec}`}>
                                <div className={`${cascading.liststyle}`}>
                                    <BsFillGrid3X2GapFill onClick={() => setListtype(true)} fill={listtype ? '#FE80C9' : '#fff'} fontSize={21} />
                                    <FaThList onClick={() => setListtype(false)} fill={!listtype ? '#FE80C9' : '#fff'} fontSize={15} />
                                </div>
                                <div className={`${cascading.togglesec}`} >

                                    <Switch
                                        // onChange={handleChange}
                                        checked={checked}
                                        className="react-switch"
                                        onHandleColor="#232833"
                                        offHandleColor='#232833'
                                        onColor="#FE80C9"
                                        // handleDiameter={10}
                                        offColor='#6a6c6e'
                                        height={20}
                                        width={40}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onChange={(e) => { stakedPools(e) }}
                                    />

                                    <span className={`${cascading.spanlabel}`} >Staked only</span>
                                </div>
                                <div className={`${cascading.tabsec}`}>
                                    <p onClick={() => setStatus(true)} className={status ? `${cascading.active}` : ''}>Live</p>
                                    <p onClick={() => setStatus(false)} className={!status ? `${cascading.active}` : ''}>Finished</p>

                                </div>


                            </div>

                        </div>
                        <div className={`offset-lg-1 col-lg-5 mb-3`}>
                            <div className={`${cascading.rightfiltersec}`}>
                                <div>
                                    <p className={`${cascading.labelname} mb-2`}>Sort by</p>
                                    <Dropdown className={`${cascading.dropdownstyle}`} onSelect={(e) => { setSortBy(e) }}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" >
                                            {sortBy}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className={`${cascading.dropdownmenustyle}`}>
                                            <Dropdown.Item eventKey='Hot' onClick={() => { HotOnclick(activeFarms) }}>Hot</Dropdown.Item>
                                            <Dropdown.Item href="#/action-1" eventKey='APR' onClick={() => { APROnclick(activeFarms) }}>APR</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2" eventKey='Multiplier' onClick={() => { MultiplierOnclick(activeFarms) }}>Multiplier</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3" eventKey='Earned' onClick={() => { EarnedOnclick(activeFarms) }}>Earned</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3" eventKey='Liquidity' onClick={() => { Liquidity(activeFarms) }}>Liquidity</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div>
                                    <p className={`${cascading.labelname} mb-2`}>Search</p>
                                    <input type="text" className={`form-control ${cascading.inputstyle}`}
                                        placeholder="Search Farms" value={searchTerm} aria-label="Username" aria-describedby="basic-addon1" onChange={onChangefn} />


                                </div>

                            </div>
                        </div>

                    </div>


                    <div className={`${cascading.farmcontentsec} mt-4`}>
                        {console.log("searchResults", searchResults)}

                        {(stakedOnly && isEmpty(searchResults)) ? (
                            <p className={`${cascading.emptylabels}`}>Only Staked Pools will be displayed
                            </p>

                        ) :
                            (isEmpty(searchResults)) && !loading && (
                                <p className={`${cascading.emptylabels}`}>No farms found!
                                </p>

                            )
                        }
                        {console.log("Loading...", loading)}

                        {loading && (
                            <p className={`${cascading.emptylabels}`}>Loading...
                            </p>
                        )}




                        {status ? <>

                            {listtype ?
                                <div className={`row`}>
                                    {searchResults && searchResults?.map((e) => (
                                        <div className={`col-md-6 col-lg-4 mb-4`}>
                                            <Farmcard datas={e} />
                                        </div>

                                    ))}

                                </div>
                                :
                                <>
                                    {searchResults && searchResults?.map((e, i) => (
                                        <div className={`row`}>
                                            <Farmlist datas={e} index={i} />

                                        </div>
                                    ))}
                                </>}

                        </>
                            :

                            isEmpty(inactiveFarms) ?
                                <>

                                    <p className={`${cascading.emptylabels}`}>These pools are no longer distributing rewards. Please unstake your tokens.</p>
                                </>
                                :
                                <>

                                    {listtype ?
                                        <div className={`row`}>
                                            {inactiveFarms && inactiveFarms?.map((e) => (
                                                <div className={`col-md-6 col-lg-4 mb-4`}>
                                                    <Farmcard datas={e} status='inactive' />
                                                </div>

                                            ))}

                                        </div>
                                        :
                                        <>
                                            {inactiveFarms && inactiveFarms?.map((e, i) => (
                                                <div className={`row`}>
                                                    <Farmlist datas={e} index={i} status='inactive' />

                                                </div>
                                            ))}
                                        </>}

                                </>




                        }


                    </div>
                </div>


            </div>



            <Footer />
        </>
    )
}
