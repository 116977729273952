import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/positionmodalstyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaInfoCircle, FaChevronLeft, FaCopy, FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle, FaRegTimesCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaCheckCircle } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';

import { useSelector } from 'react-redux';



import { IoMdClose } from "react-icons/io";

//hooks
import { ApproveToken } from '../hooks/useTokens';
import { getWalletAddress } from '../lib/localStorage';
import { WithdrawFromPosition } from '../hooks/useNFTpool';
import { toFixedNumber } from '../lib/FixedNumber';
import { roundToSignificant } from '../hooks/useCommon';
import { isEmpty } from '../lib/isEmpty';
export default function Withdrawpositionmodal(props) {

  const [withdrawpositionmodal, setWithdrawpositionmodal] = useState(true);
  const [amount, setAmount] = useState('')
  const [Remainingamount, setRemainingamount] = useState('0')
  const [errors, setErrors] = useState({})
  const {onSuccess, SetsuccessLoader, setError } = props
  const { positionrecord } = useSelector((state) => (state.position))

  //onChange func
  const SetMax = () => {
    try {
      setErrors({})
      setAmount(positionrecord.amount)
      let Remaining = parseFloat(positionrecord.amount)-parseFloat(positionrecord.amount)
      setRemainingamount(Remaining)
    } catch (err) {
      console.log(err, 'SetMax__err')
    }
  }

  const handleChange = (e) => {
    try {
      const { name, value } = e.target
      setErrors({})
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (!numbers.test(value) && value !== "") {
        return false
      }
      if (name == 'amount') {
        setAmount(value)
        if(value != ''){
          let Remaining = parseFloat(positionrecord.amount)-parseFloat(value)
          setRemainingamount(Remaining)
        }
      }
    } catch (err) {
      console.log(err, 'onChange__err')
    }
  }

  //submit
  const handleSubmit = async() => {
    try {
      let value  = {
        amount:amount
      }
      let isValidate = validation(value)
      if(!isEmpty(isValidate)){
        setErrors(isValidate)
        return false
      }
      console.log('handleSubmit')
      let Amount = toFixedNumber(amount * 10 ** 18)
      Amount = parseFloat(Amount).toFixed(0)
      let loaderText = 'Withdrawing liquidity...'
      onSuccess(loaderText, 'Withdraw liquidity')
      let Result =  await WithdrawFromPosition(positionrecord.poolAddress,positionrecord.tokenId,Amount)
      if(Result){
        let successLoaderText = `You've successfully withdrawn ${amount} of ${positionrecord.lpsymbol} liquidity`
        SetsuccessLoader(successLoaderText, Result.transactionHash)
      }else{
        let errorText = `Error ! When withdraw amount from position`
        setError(errorText)
      }
    } catch (err) {
      console.log(err, 'handleSubmit__err')
    }
  }

  const validation =(value)=>{
    try{
      let error = {}
      console.log(parseFloat(value.amount) > parseFloat(positionrecord.amount),'validation')
      if(isEmpty(value.amount)){
        error['amount'] = 'Amount feild is required'
      }else if(parseFloat(value.amount) > parseFloat(positionrecord.amount)){
        error['amount'] = 'Insufficient amount to be withdraw'
      }
      return error
    }catch(err){
      console.log(err,'validation__err')
    }
  }
  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={withdrawpositionmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Withdraw from your Position</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={() => { props.onHide(); props.onhideposition(); }} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                </div>
                <div>
                  <p className={`${cascading.coinname} mb-0`}>{positionrecord.lpsymbol}</p>
                  <p className={`${cascading.coinnamegrey} mb-0`}>#ID-{positionrecord.tokenId}</p>
                </div>


              </div>


              {/* <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p> */}
              <p className={`${cascading.tokenvaluedesc} mt-3`}>Recover underlying tokens from a spNFT.</p>





              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Amount
              </p>
              {/* <p className={`${cascading.tokenvaluedesc} text-start`}>You need to own DSP-BNB LP tokens to directly add more liquidity to this position. If that's not the case, head to the liquidity page first.</p> */}


              <div className={`${cascading.inputsec} my-3`}>
                <div className={`input-group mb-3`}>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="0"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={amount}
                    name='amount'
                    onChange={(e) => { handleChange(e) }}
                  />
                  <div className={`input-group-append`} >
                    <button
                      className={` ${cascading.inputspan}`}
                      id="basic-addon1"
                      onClick={() => {
                        SetMax()
                      }}
                    >
                      Max
                    </button>
                  </div>
                </div>
                <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>
                <p className={`${cascading.balsec}`}>Total deposit : {roundToSignificant(positionrecord.amount,6)} {positionrecord.lpsymbol}</p>
              </div>

              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Withdraw amount</p>
                </div>
                <div className='col-4 col-md-4'>
                  {/* <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>$2.05</p> */}
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(amount,6)}</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Remaining amount</p>
                </div>
                <div className='col-4 col-md-4'>
                  {/* <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>6.38%</p> */}
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(Remainingamount,6)}</p>
                </div>
              </div>

              {/* <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>1.02%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Lock bonus APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>7.41%</p>
                </div>
              </div>

              <div className={`${cascading.warning_border} mt-4`}>
                <div className='d-flex align-items-start justify-content-start'>
                  <FaInfoCircle className={`${cascading.themeicon}`} />
                  <span className={`${cascading.label}`}>By making a new deposit on this position, you will renew its lock for 4 days from now.</span>
                </div>

              </div> */}
            </div>





            <div className='mt-4'>


             {isEmpty(amount) ?
             <button
             className={`btn ${cascading.connectwalletbtn}`}
             type='button'
             disabled={true}
           >
             Enter amount
           </button>
           :
              <button
                className={`btn ${cascading.connectwalletbtn}`}
                type='button'
                onClick={() => {
                  // props.onHide();
                  // props.onSuccess();
                  handleSubmit()
                }}
              >
                Withdraw
              </button>}

              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>
            </div>
          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

