//demo
export default {
    nativetoken: {

        //bsc
        56: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45',  //M
        97: '0xEcEb014048E59398eC5BAfE5bc9361AD66C8f218', //T

        //Eth
        1: '0xBdf1f2b34B480A05fF3dbF848b5A1efA106804a8', //M
        11155111: '0xbF985636FAa141d222A802CDf51AAA2171F58fFf', //T

        //Avax
        43114: '0xBdf1f2b34B480A05fF3dbF848b5A1efA106804a8', //M
        43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    xtoken: {

        //bsc
        56: '0xc5c26A06FF435859d32c0D07c4418d8877b1E1dB', //M
        97: '0x846FE8E884B862F720Cc7728eE42f1a29300AB17',//T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    dividends: {

        //bsc
        56: '', //M
        97:'0x87B8FFc91A8805e58e8CF72F6b7D936b0174d3B4', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    multicall: {

        //bsc
        56: '0xc2DC6f4f0a87F2CA78cB0c5b6B3C0B9A5f7be9c6', //M
        97: '0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe', //T

        //Eth
        1: '', //M
        11155111: '0xE8039f1fbbd2531AA92f553De008510CdA2F7EB9', //T

        //Avax
        43114: '0xC049F2a686BeD7a27C7f20c657FBd88446F368F5', //M
        43113: '0x279A2739dF44E9E12968909968985bAd8e5520dB', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    router: {

        //bsc
        56: '0xC4e02705F9513fB3ce120F95aB8a0d8c4F0419bf', //M
        97:'0x173b336C11167fF4141E1D19fE4C5d93837b7FB6',  //T

        //Eth
        1: '', //M
        11155111: '0x7C41926caEC2490C32f2388C8eBA1B3cAb9D7c21', //T

        //Avax
        43114: '0xD37836684547e0aDed54e3a1831F0A7ff6952719', //M
        43113: '0xC4e02705F9513fB3ce120F95aB8a0d8c4F0419bf', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    factory: {
        //bsc
        56: '0xD37836684547e0aDed54e3a1831F0A7ff6952719', //M
        97:'0x7079DdAfd1DB8B75822Dc1f6F6F034F8c14B3b53',//T

        //Eth
        1: '', //M
        11155111: '0x4A2d94f887841e760468f9c0Ee7b4a6A870fDf70', //T

        //Avax
        43114: '0xc5c26A06FF435859d32c0D07c4418d8877b1E1dB', //M
        43113: '0xc5c26A06FF435859d32c0D07c4418d8877b1E1dB', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    masterChef: {

        //bsc
        56: '0xC049F2a686BeD7a27C7f20c657FBd88446F368F5', //M
        97: '0xd1d82FDC5Bf119260d5Ae55EAC0d6Ab507A57C63',//T

        //Eth
        1: '', //M
        11155111: '0x015f856b67133936dd5DA05817D1E509798F5c29', //T

        //Avax
        43114: '0x38EaA8cdEDcbF8B66914E7fb38D04a47ACA49b61', //M
        43113: '0xC049F2a686BeD7a27C7f20c657FBd88446F368F5', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    SmartChefFactory: {

        //bsc
        56: '0x38EaA8cdEDcbF8B66914E7fb38D04a47ACA49b61', //M
        97: '0x036068610C528e3a8D8726c1A7ebDFF39a82Eb12',//T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '0x38EaA8cdEDcbF8B66914E7fb38D04a47ACA49b61', //M
        43113: '0x38EaA8cdEDcbF8B66914E7fb38D04a47ACA49b61', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    poolAddress: {

        //bsc
        56: '', //M
        97: '0x6e8F15C4ce04E5ACeE231d158399006775D322D8', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    master: {

        //bsc
        56: '', //M
        97:'0x426e9cD984a65581Ea8e151EF4181E02B00E3C76',  //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    NFTPoolFactory:{
         //bsc
         56: '', //M
         97:'0xE59c664FAB555C944F1FdA1c35Dc47927C62c7E1',//T
 
         //Eth
         1: '', //M
         11155111: '', //T
 
         //Avax
         43114: '', //M
         43113: '', //T
 
         //Arbitrum
         42161: '', //M
         421614: '', //T
 
         //zkSync
         324: '', //M
         280: '', //T
    },
    yeildBooster:{
        //bsc
        56: '', //M
        97: '0xe4663C4716FF909149612a25CD46Bca3431fedB0', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T
    },
    positionhelper:{
        //bsc
        56: '', //M
        97:'0xB575933dA19be71d80b6d2B70138E8De8f778145', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T
    }
}

export const PROXY = {
	56: "", //bsc mainnet

    97: '0x9b1CB0dA9ac2Feb3107b9027897e311421736F9c',// new bsc testnet

    137: "", //polygon mainnet

	1: "",

	3: "",

	43113 :"", // avalanche testnet 

	42161 : ""  //arbitrum
};


export const XLAUNCH = {
	56: "", //bsc mainnet

    97: '0x317a0AaaDc9c98684cD7e2F8dbA29594bf7C1367',//bsc testnet

    137: "", //polygon mainnet

	1: "",

	3: "",

	43113 :"", // avalanche testnet 

	42161 : ""  //arbitrum
};