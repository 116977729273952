import React, { useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/yourwalletstyle.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { FaRegCopy, FaExternalLinkAlt, FaCopy } from "react-icons/fa";
import { getWalletAddress, removeWallteAddress, walletControl } from '../lib/localStorage';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";


import { WALLET_ADDRESS } from '../constants';

import { isEmpty } from '../lib/isEmpty';
//hooks
import { CHAINS } from '../config/env';
import { GetChainIndex } from '../hooks/useWeb3';

import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';
import { GetCurrencyBalance } from '../hooks/useTokens';
import { roundToSignificant } from '../hooks/useCommon';
export default function Yourwallet(props) {
  const dispatch = useDispatch()
  const userdetail = useSelector((state) => state.admin)
  const walletdetail = useSelector((state) => state.wallet)
  const [tabwallet, setTabwallet] = useState(props.statedata)
  const [walletBalance, setwalletBalance] = useState(0)
  const [addLiquidity, setAddLiquidity] = useState("")
  const [removeLiquidity, setRemoveLiquidity] = useState("")
  const [swap, setSwap] = useState("")
  const [approve, setApprove] = useState("")

  const transaction = async () => {
    if (localStorage.getItem('addLiquidity')) {
      setAddLiquidity(localStorage.getItem('addLiquidity'))
    }
    if (localStorage.getItem('removeLiquidity')) {
      setRemoveLiquidity(localStorage.getItem('removeLiquidity'))
    }
    if (localStorage.getItem('swap')) {
      setSwap(localStorage.getItem('swap'))
    }
    if (localStorage.getItem('approve')) {
      setApprove(localStorage.getItem('approve'))
    }
  }
  const walletdis = () => {
    dispatch(walletControl('disconnect'))
    dispatch({
      type: WALLET_ADDRESS,
      payload: ''
    })
    removeWallteAddress()
    sessionStorage.removeItem('wallet')
    sessionStorage.setItem('walletStatus', 'disconnect')
  }

  useEffect(() => {
    fetchBalance()
    transaction()
  }, [walletdetail])

  const fetchBalance = async () => {
    try {
      if (getWalletAddress()) {
        let balance = await GetCurrencyBalance()
        setwalletBalance(balance)
      }

    } catch (err) {
      console.log(err, 'fetchBalance__err')
    }
  }
  const clearAll = async () => {
    localStorage.removeItem("addLiquidity")
    localStorage.removeItem("removeLiquidity")
    localStorage.removeItem('swap')
    localStorage.removeItem('approve');
    setAddLiquidity("")
    setRemoveLiquidity("")
    setSwap("")
    setApprove("")
  }
  return (
    <div className={`${cascading.walletmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Your Wallet</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
         
          {/* <button className={`${cascading.closebtn}`} onClick={props.onHide}>X</button> */}
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.tabsec}`}>
            <button onClick={() => setTabwallet(true)} className={tabwallet ? `btn ${cascading.tabbtn} ${cascading.active}` : `btn ${cascading.tabbtn}`}>Wallet</button>
            <button onClick={() => setTabwallet(false)} className={!tabwallet ? `btn ${cascading.tabbtn} ${cascading.active}` : `btn ${cascading.tabbtn}`}>Transactions</button>
          </div>
          <div className={`${cascading.tabinnersec}`}>
            {tabwallet ?
              <>
                <div className={`mb-4`}>
                  <p className={`${cascading.label}`}>YOUR ADDRESS</p>
                  <div className={`input-group mb-3 mt-3 ${cascading.copyinput}`}>
                    <input type="text" class="form-control" value={`${(walletdetail?.walletaddress).substring(0, 5)}...${walletdetail?.walletaddress.substring(38, 42)}`} placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    <span class="input-group-text" id="basic-addon2">
                      <CopyToClipboard
                        text={walletdetail?.walletaddress}
                        onCopy={() =>
                          toast.success('Address copied')
                          // toast('Address copied', {
                          //   duration: 2000,
                          //   position: 'top-right',

                          //   // Styling
                          //   style: { background: "#1c2029", color: "#fff", minWidth: 200 },
                          //   className: '',

                          //   // Custom Icon

                          //   // Change colors of success/error/loading icon
                          //   iconTheme: {
                          //     primary: '#000',
                          //     secondary: '#fff',
                          //   },

                          //   // Aria
                          //   ariaProps: {
                          //     role: 'status',
                          //     'aria-live': 'polite',
                          //   },
                          // })
                        }

                      >
                        {/* <button className={`mb-0 ${cascading.copybtntrans}`}>
                          <FaCopy />
                        </button> */}
                        <FaRegCopy fill='#fff' />
                      </CopyToClipboard>
                      {/* <FaRegCopy fill='#fff' /> */}
                    </span>
                  </div>
                </div>
                <div className={`${cascading.balrow}`}>
                  <p>{CHAINS[GetChainIndex()].SYMBOL} Balance</p>
                  <div className={`${cascading.rightside}`}> <p>{roundToSignificant(walletBalance, 6)}</p>
                    <button className={`${cascading.declabel}`}
                      onClick={() => {
                        window.open(`${CHAINS[GetChainIndex()].Testnet}/${walletdetail.walletaddress}}`, '_blank');
                        // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("addLiquidity")}`
                      }}>
                      View on BscScan
                      <FaExternalLinkAlt />
                    </button>
                  </div>
                </div>
              </> :
              <>
                {/* <p className={`${cascading.label}`}>RECENT TRANSACTIONS</p>
                <div className={`${cascading.norecent}`}>
                  <p className={`${cascading.recentlabel}`}>No recent transactions</p>
                </div> */}
                {
                  isEmpty(addLiquidity) && isEmpty(removeLiquidity) && isEmpty(swap) && isEmpty(approve) ?
                    <p className={`${cascading.recentlabel}`}>No recent transactions</p> : ''
                }
                {!isEmpty(addLiquidity) || !isEmpty(removeLiquidity) || !isEmpty(swap) || !isEmpty(approve) ?
                  <div className={`${cascading.clrsec} mb-4`}>
                    {/* <p className={` ${cascading.label}`}>Recent transactions</p> */}
                    <button className={`ms-auto btn ${cascading.clrbtn}`} onClick={() => { clearAll() }}>Clear all</button>
                  </div> : ""}


                {!isEmpty(addLiquidity) ?
                  <button
                    className={`${cascading.translist} ${cascading.translistcenter}`}
                    onClick={() => {
                      window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("addLiquidity")).txhash}`, '_blank');
                      // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("addLiquidity")}`
                    }} >
                    {JSON.parse(localStorage.getItem("addLiquidity")).qureyString}
                    <FaExternalLinkAlt fill='#fff' />
                    {/* <TiTick /> */}
                  </button> : ""}

                {!isEmpty(removeLiquidity) ?
                  <button
                    className={`${cascading.translist} ${cascading.translistcenter}`}
                    onClick={() => {
                      window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("removeLiquidity")).txhash}`, '_blank');
                      // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("removeLiquidity")
                    }} >
                    {JSON.parse(localStorage.getItem("removeLiquidity")).qureyString}
                    <FaExternalLinkAlt fill='#fff' />
                    {/* <TiTick /> */}
                  </button> : ""}

                {!isEmpty(swap) ?
                  <button
                    className={`${cascading.translist} ${cascading.translistcenter}`}
                    onClick={() => {
                      window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("swap")).txhash}`, '_blank');
                      // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("swap")}`
                    }} >
                    {JSON.parse(localStorage.getItem("swap")).qureyString}
                    <FaExternalLinkAlt fill='#fff' />
                    {/* <TiTick /> */}
                  </button> : ""}

                {!isEmpty(approve) ?
                  <div className='text-center'>
                    <button
                      className={`${cascading.translist} ${cascading.translistcenter}`}
                      onClick={() => {
                        window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem('approve')).txhash}`, '_blank');
                        // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("approve")}`
                      }} >
                      {JSON.parse(localStorage.getItem('approve')).qureyString} <FaExternalLinkAlt fill='#fff' />
                      {/* <TiTick /> */}
                    </button> </div> : ""}
              </>
            }
          </div>
        </Modal.Body>
        {tabwallet &&
          <Modal.Footer className={`${cascading.modalclr} ${cascading.modalfooter}`}>
            <Button className={`${cascading.btnstyle}`} onClick={() => { walletdis(); props.onHide() }}>
              Disconnect Wallet
            </Button>
          </Modal.Footer>
        }
      </Modal>
    </div>
  )
}

