import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BiRightArrowAlt } from "react-icons/bi";
import { FaAngleLeft } from "react-icons/fa";
import Redeemcard from './RedeemCard';

import { useSelector } from 'react-redux';

export default function Vestingcard(props) {
    const { redeemDetails } = useSelector((state) => state.xtoken)
    return (
        <div className={`mt-3 mb-4 ${cascading.approvecard}`}>
            <div className={`container`}>
                <div className={`row pt-4 ${cascading.headrow}`}>
                    <p className={`${cascading.pagehead}`}>Vesting</p>
                    <p className={`text-uppercase ${cascading.pagedesc}`}>Pending</p>
                </div>
                {
                    redeemDetails.length > 0 ? redeemDetails.map((val, i) => {
                        return (
                            <Redeemcard val = {val} index ={i}/>
                        )
                    }) : ''
                }

            </div>
        </div>
    )
}
