import React, { useEffect, useState } from 'react'
import cascading from '../assests/css/footerstyle.module.css';
import { FaTelegramPlane } from "react-icons/fa";
import logo from '../assests/images/footerlogo.png';
import minilogo from '../assests/images/logomini.png';

import media1 from '../assests/images/media1.png';
import mediahover1 from '../assests/images/mediahover1.png';

import media2 from '../assests/images/media2.png';
import mediahover2 from '../assests/images/mediahover2.png';

import media3 from '../assests/images/media3.png';
import mediahover3 from '../assests/images/mediahover3.png';

import media4 from '../assests/images/media4.png';
import mediahover4 from '../assests/images/mediahover4.png';

import { Link } from 'react-router-dom';
import { IoMdMail } from "react-icons/io";
import { FaFile } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";


import { CHAINS, FRONT_URL,ANALYTICS_URL } from '../config/env';
import { getTokenPricesFromFarm } from '../hooks/usePools';
import { getNativeAddress } from '../hooks/useNativeToken';
import { roundToSignificant } from '../hooks/useCommon';

import { useSelector } from 'react-redux';

export default function Footer() {
  const { totalTvl } = useSelector((state) => (state.nftpool))
  const [nativePrice,SetnativePrice] = useState(0)
  useEffect(()=>{
    NativePrice()
  },[])
  const NativePrice = async()=>{
    try{
      let farms = JSON.parse(localStorage.getItem("Farms"))
      let price = await getTokenPricesFromFarm(farms)
      let nativeAddress  =  getNativeAddress()
      let dspinusd = price[nativeAddress.toLowerCase()]
      SetnativePrice(dspinusd)
    }catch(err){
      console.log(err,'NativePrice__Err')
    }
  }
  return (
    <>
      <div className={`${cascading.footersec}`}>
        <div className={`container`}>
          <div className={`row ${cascading.row_marginminus}`}>
            <div className={`col-sm-12 col-lg-2 ${cascading.leftsec}`}>
              <div className={`row ${cascading.leftsecrowmargin}`}>
                {/* <div className={`col-sm-6 col-lg-12`}>
              <img src={logo} alt='logo' className={`img-fluid ${cascading.logoimg}`}/>
                </div> */}
                <div className={`col-sm-6 col-md-4 col-lg-12`}>
                  <div className={`${cascading.socialsec} d-none d-lg-block`}>
                    <div className={`${cascading.dflex}`}>
                      {/* <ul>
                        <li> */}
                      <Link to="https://x.com/DonutSwapDEX" target='_blank'>
                        {/* <RiTwitterXFill /> */}
                        <img src={media1} className={`img-fluid ${cascading.mediass}`} />
                        <img src={mediahover1} className={`img-fluid ${cascading.mediasshover}`} />
                        {/* <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>  */}
                      </Link>
                      {/* </li>
                        <li> */}
                      <Link to="https://t.me/Donut_Swap" target='_blank'>
                        {/* <FaTelegramPlane />  */}
                        <img src={media2} className={`img-fluid ${cascading.mediass}`} />
                        <img src={mediahover2} className={`img-fluid ${cascading.mediasshover}`} />
                      </Link>
                      {/* </li>
                        <li> */}
                      <Link to="https://whitepaper.donutswap.finance" target='_blank'>
                        {/* <FaFile />  */}
                        <img src={media3} className={`img-fluid ${cascading.mediass}`} />
                        <img src={mediahover3} className={`img-fluid ${cascading.mediasshover}`} />
                      </Link>
                      {/* </li>
                        <li> */}
                      <Link to="https://donutswap-1.gitbook.io/donutswap/" target='_blank' >
                        {/* <IoMdMail /> */}
                        <img src={media4} className={`img-fluid ${cascading.mediass}`} />
                        <img src={mediahover4} className={`img-fluid ${cascading.mediasshover}`} />
                      </Link>
                      {/* </li>
                    </ul> */}
                    </div>
                  </div>
                  <div className={` ${cascading.rightsec} ${cascading.rightsecmble} d-block d-lg-none`}>
                    <div className={`${cascading.labelsec} mb-3`}>
                      <img src={minilogo} alt='logo' className={`img-fluid ${cascading.minilogo}`} />
                      <p className={`${cascading.labeltxt}`}>${roundToSignificant(NativePrice,6)}</p>
                    </div>
                    <div className={`${cascading.labelsec} mb-3`}>
                      <p className={`${cascading.labeltxt}`}>TVL</p>
                      <p className={`${cascading.labeltxt}`}>${roundToSignificant(totalTvl,6)}</p>
                    </div>

                  </div>
                </div>
              </div>


            </div>
            <div className={` col-lg-8 ${cascading.centersec}`}>
              <div className={`row`}>
                <div className={`col-sm-6 col-lg-3 ${cascading.routerow}`}>
                  <ul className={`${cascading.firstul}`}>
                    <li><p className={`${cascading.title}`}>Resources</p></li>
                    <li><Link to="/" className={`${cascading.pageroute}`} target='_blank'>Audit</Link></li>
                    <li><Link to="https://t.me/Donut_Swap" className={`${cascading.pageroute}`} target='_blank'>Contact us</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`} target='_blank'>Contracts</Link></li>
                    <li><Link to="https://whitepaper.donutswap.finance" className={`${cascading.pageroute}`} target='_blank'>Documentation</Link></li>
                  </ul>
                </div>
                <div className={`col-sm-6 col-lg-3 ${cascading.routerow}`}>
                  <ul className={`${cascading.secondul}`}>
                    <li><p className={`${cascading.title}`}>Help</p></li>
                    <li><Link to="https://whitepaper.donutswap.finance" className={`${cascading.pageroute}`} target='_blank'>FAQ</Link></li>
                    <li><Link to="https://whitepaper.donutswap.finance" className={`${cascading.pageroute}`} target='_blank'>Guides</Link></li>
                    <li><Link to="https://t.me/Donut_Swap" className={`${cascading.pageroute}`} target='_blank'>Support</Link></li>
                  </ul>
                </div>
                <div className={`col-sm-6 col-lg-3 ${cascading.routerow}`}>
                  <ul className={`${cascading.thirdul}`}>
                    <li><p className={`${cascading.title}`}>Tools</p></li>
                    <li><Link to={`${ANALYTICS_URL}/info`}className={`${cascading.pageroute}`}>Analytics</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`}>Bridge</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`}>CoinGecko</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`}>DexTools</Link></li>
                    {/* <li><Link to="#" className={`${cascading.pageroute}`}>GeckoTerminal</Link></li> */}
                    <li><Link to="/" className={`${cascading.pageroute}`}>Governance</Link></li>
                  </ul>
                </div>
                <div className={`col-sm-6 col-lg-3 ${cascading.routerow}`}>
                  <ul className={`${cascading.fourthul}`}>
                    <li><p className={`${cascading.title}`}>Ecosystem</p></li>
                    <li><Link to="/yumpools" className={`${cascading.pageroute}`}>Create a pool</Link></li>
                    <li><Link to="/pools" className={`${cascading.pageroute}`}>My pools</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`col-sm-6 col-lg-2 ${cascading.rightsec} d-none d-lg-flex`}>
              <div className={`${cascading.labelsec} mb-3`}>
                <img src={minilogo} alt='logo' className={`img-fluid ${cascading.minilogo}`} />
                <p className={`${cascading.labeltxt}`}>${roundToSignificant(nativePrice,6)}</p>
              </div>
              <div className={`${cascading.labelsec} mb-3`}>
                <p className={`${cascading.labeltxt}`}>TVL</p>
                <p className={`${cascading.labeltxt}`}>${roundToSignificant(totalTvl,6)}</p>
              </div>

            </div>


          </div>
          <div className={`${cascading.copysec}`}>

            <p className={`${cascading.copytext}`}>Copyright © 2024 DONUTSWAP - All Rights Reserved.</p>
            <div className={`${cascading.socialsec} d-block d-lg-none`}>
              <div className={`${cascading.dflex}`}>
                <Link to="https://x.com/DonutSwapDEX" target='_blank'>
                  <img src={media1} className={`img-fluid ${cascading.mediass}`} />
                  <img src={mediahover1} className={`img-fluid ${cascading.mediasshover}`} />
                </Link>
                <Link to="https://t.me/Donut_Swap" target='_blank'>
                  <img src={media2} className={`img-fluid ${cascading.mediass}`} />
                  <img src={mediahover2} className={`img-fluid ${cascading.mediasshover}`} />
                </Link>
                <Link to="https://whitepaper.donutswap.finance" target='_blank'>
                  <img src={media3} className={`img-fluid ${cascading.mediass}`} />
                  <img src={mediahover3} className={`img-fluid ${cascading.mediasshover}`} />
                </Link>
                <Link to="https://donutswap-1.gitbook.io/donutswap/" target='_blank' >
                  <img src={media4} className={`img-fluid ${cascading.mediass}`} />
                  <img src={mediahover4} className={`img-fluid ${cascading.mediasshover}`} />
                </Link>
              </div>

            </div>
          </div>

        </div>

      </div>



    </>
  )
}
