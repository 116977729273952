import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/addliquiditystyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaSpinner, FaCheckCircle, FaInfoCircle } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/coinicon.png';





import { IoMdClose } from "react-icons/io";

//env
import { CHAINS } from '../config/env'
import { GetChainIndex } from '../hooks/useWeb3'
export default function Addsuccessmodal(props) {

  const [modalsucess, setmodalsuccess] = useState(true);

  const { lpSymbol, isLoader, loaderText, successText, hash, title,errorText } = props

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={modalsucess} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>{title}</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>
            <div>
              {
                isLoader == false ?
                  <>
                    <div className={`${cascading.imgsec}`}>
                      <div className={`${cascading.leftimgsec}`}>
                        <img src={coinlogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                        <img src={bnblogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                      </div>
                      <p className={`${cascading.coinname}`}>{lpSymbol}</p>
                    </div>
                    <div className='text-center'>
                      <FaSpinner className={`${cascading.loadspinner}`} />
                    </div>
                    <p className={`${cascading.textwhitedesclg} mt-4`}>{loaderText}</p>
                    <p className={`${cascading.textgreydesclg}`}>Confirm tx from your wallet</p>

                  </>
                  :
                  <>
                    {isLoader ?
                      <>
                        <div className='text-center'>
                          <FaCheckCircle className={`${cascading.circleplus}`} />
                        </div>
                        {/* <p className={`${cascading.textwhitedesclg} mt-4`}>You've successfully provided
                          <span>~$2.04 of DSP-BNB
                          </span>liquidity.</p> */}
                        <p className={`${cascading.textwhitedesclg} mt-4`}>{successText}</p>
                        <p className={`${cascading.textgreydesclg}`}>Head to the dashboard page or the pool page to check your position.</p>
                        <div className='text-center'>
                          <button className={`${cascading.declabel}`}
                            onClick={() => {
                              window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${hash}`, '_blank');
                            }}
                          >View TX</button>
                        </div>
                      </>
                      :
                      isLoader == undefined ?
                        <>
                          <div className='text-center'>
                            <FaInfoCircle className={`${cascading.infoplusred}`} />
                          </div>
                          <p className={`${cascading.textwhitedesclg} mt-4`}>{errorText}</p>
                        </> : ''
                    }
                  </>
              }
            </div>
          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

